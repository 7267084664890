import React, {FC, useContext, useState, MouseEvent, FormEvent, useEffect, useRef} from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import './style.css'
import Loader from '../../components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import {useLocation} from "react-router-dom";
import {IGuild} from "../../models/IGuild";
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import UserElement from "../../components/UserElement/UserElement";

const DashboardBackUps: FC = () => {
	const { store } = useContext(AuthContext)

	const [disabled, setDisabled] = useState<boolean>(false);
	const [updateState, setUpdateState] = useState<boolean>(false);
	const location = useLocation()

	const refUserAccessInput = useRef<HTMLInputElement>(null)

	store.setDisabledSettings = null;

	useEffect(() => {
	}, []);

	if (store.isLoading) {
		return <Loader text='Получаю данные'/>
	}

	if (store.validateData() || !store.guild) {
		return store.base_guild_dashboard(location, <ErrorServer text='Не удалось получить данные с сервера'/>)
	}

	const guild: IGuild = store.guild

	async function updateSettings(withState: boolean | void = true): Promise<void> {
		if (store.changed) return
		withState && setUpdateState((prev) => !prev);
		store.setChanged(true);
	}



	return (
		<div className='indevelopment'>
			В разработке
		</div>
	)
};

export default observer(DashboardBackUps);