import './style.css'
import React, {FC, FormEvent, MouseEvent} from 'react';
import {Switch} from "antd";
import {ISendSettings} from "../../models/ISendSettings";
import {IGuildChannel} from "../../models/IGuildChannel";
import updateSettings from "../../types/updateSettings";
import SendSettingsForgotText from "../SendSettingsForgotText/SendSettingsForgotText";
import { ReactComponent as CrossMarkLogo } from '../../assets/cross-mark.svg';
import {getChannelName} from "../../utils/channelUtils";
import SendSettingsDeleteAfter from "../SendSettingsDeleteAfter/SendSettingsDeleteAfter";
import PunishmentDuration from "../PunishmentDuration/PunishmentDuration";
import {log} from "console";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";

interface IProps {
    violation: string
    channel_id: string
    send_settings: { [key: string | number]: ISendSettings }
    channel: ISendSettings
    guildChannels: IGuildChannel[]
    availableChannels: IGuildChannel[]
    updateSettings: updateSettings
}


const SendSettings: FC<IProps> = (props)  => {
    async function OnSelectChannel(event: FormEvent<HTMLSelectElement>) {
        const channel_id = event.currentTarget.selectedOptions.item(0)?.value
        if (!channel_id) return

        runInAction(() => {
            delete props.send_settings[props.channel_id]
            props.send_settings[channel_id] = props.channel;
        })
        await props.updateSettings(true)
    }

    async function OnChangeShowState(checked: boolean) {
        if (props.channel.show_text !== checked) {
            runInAction(() => {
                props.channel.show_text = checked
            })
            await props.updateSettings(true);
        }
    }

    async function onDelete(event: MouseEvent<HTMLButtonElement>) {
        delete props.send_settings[props.channel_id]
        await props.updateSettings(true);
    }

    const channel = props.guildChannels.find((elem) => {
        return elem.id === props.channel_id
    })

    let availableChannels;
    if (channel !== undefined) {
        availableChannels = [channel, ...props.availableChannels]
    } else {
        availableChannels = props.availableChannels
    }
    
    return (
        <div className='send-settings'>
            <div className='send-settings-header'>
                <select defaultValue={channel?.id} onChange={OnSelectChannel}>
                    {availableChannels.map((elem) => {
                        return <option value={elem.id}>{getChannelName(elem)}</option>
                    })}
                </select>
            </div>
            <hr/>
            <div className='send-settings-container'>
                {props.violation !== 'hacked_account' &&
                    <div className='send-settings-show-text-container'>
                        <span className='send-settings-title'>
                            Отображать текст
                        </span>
                        <div className='send-settings-show-text-switch'>
                            <Switch checked={props.channel.show_text} size='100px' onChange={OnChangeShowState}/>
                        </div>
                    </div>
                }
                {props.channel.show_text && props.violation !== 'hacked_account' &&
			        <div className='send-settings-forgot-text-container'>
                        <span className='send-settings-title'>
                            Замазывать текст
                        </span>
				        <SendSettingsForgotText send_settings={props.channel} updateSettings={props.updateSettings}/>
			        </div>
                }
                <div className='send-settings-delete-after-container'>
                     <span className='send-settings-title'>
                            Удалить сообщение через
                        </span>
                    <div className='send-settings-delete-after-grid'>
                        <SendSettingsDeleteAfter type='m' name='минут'
                                                 time={Math.floor(props.channel.delete_after / 60)}
                                                 settings={props.channel} updateSettings={props.updateSettings}/>
                        <SendSettingsDeleteAfter type='s' name='секунд'
                                                 time={Math.floor(props.channel.delete_after % 60)}
                                                 settings={props.channel} updateSettings={props.updateSettings}/>
                    </div>
                </div>
                <div className='send-settings-delete'>
                    <button className='send-settings-delete-button' onMouseUp={onDelete}>
                        <CrossMarkLogo width='20px' height='20px'/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default observer(SendSettings);
