import React, {FC, MouseEvent, useRef} from "react";
import Store from "../../store/store";
import {IPermissions} from "../../models/IPermissions";
import {IImmuneRule} from "../../models/IImmuneRule";
import ChannelElement from "../ChannelElement/ChannelElement";
import {IGuild} from "../../models/IGuild";
import {IGuildChannel} from "../../models/IGuildChannel";
import {getChannelName} from "../../utils/channelUtils";
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";
import ViolationEnum from "../../enums/Violation";
import './style.css'
import { ReactComponent as CrossMarkLogo } from '../../assets/cross-mark.svg';

interface IProps {
	store: Store
	guild: IGuild
	permissions: IPermissions
	immuneRule: IImmuneRule
	availableChannels: IGuildChannel[]
	updateSettings: (withState: boolean) => Promise<void>;
}

interface IChannel {
	id: string
	type: number
}

const PermissionsImmuneRule: FC<IProps> = (props) => {
	const refChannelSelect = useRef<HTMLSelectElement>(null)
	const refViolationSelect = useRef<HTMLSelectElement>(null)

	let avalilableViolations = []

	const listViolationsEnum: [string, string | ViolationEnum][] = Object.entries(ViolationEnum).filter((k, _) => typeof k[1] === 'number')
	for (let i = 0; i < listViolationsEnum.length; i++) {
		// @ts-ignore
		if  (((1 << listViolationsEnum[i][1]) & props.immuneRule.violations) === 0) {
			avalilableViolations.push(listViolationsEnum[i])
		}
	}

	async function onAddViolation() {
		if (refViolationSelect === null || refViolationSelect.current === null) return

		const value = parseInt(refViolationSelect.current.value, 10)
		if (Number.isNaN(value)) return

		runInAction(() => {
			props.immuneRule.violations = props.immuneRule.violations | (1 << value)
		})
		await props.updateSettings(true)
	}

	async function onDeleteViolation(value: number) {
		runInAction(() => {
			props.immuneRule.violations = props.immuneRule.violations & (~value)
		})
		await props.updateSettings(true)
	}

	async function onDeleteChannel(value: IChannel) {
		for (let i = 0; i < props.immuneRule.channels.length; i++) {
			if (props.immuneRule.channels[i].id === value.id) {
				await props.updateSettings(true);
				runInAction(() => {
					props.immuneRule.channels.splice(i, 1)
				})
				break
			}
		}
	}

	async function onAddChannel() {
		if (!refChannelSelect || !refChannelSelect.current) return
		const channelId = refChannelSelect.current.value;
		// check limit
		if (props.guild.channelId2channel[channelId] !== undefined) {
			runInAction(() => {
				props.immuneRule.channels.push({
					'type': props.guild.channelId2channel[channelId].type,
					'id': channelId})
			})
		}

	}

	async function onDelete(event: MouseEvent<HTMLButtonElement>) {
		for (let i = 0; i < props.permissions.immune_rules.length; i++) {
			if (props.immuneRule.id === props.permissions.immune_rules[i].id) {
				runInAction(() => {
					props.permissions.immune_rules.splice(i, 1)
				})
				await props.updateSettings(true)
				break
			}
		}
	}

	return (
		<div className='permission-block permission-immune-rule-block'>
			<span className='permissions-sub-block-title'>
				Каналы
			</span>
			<div className='permissions-channels'>
				{props.immuneRule.channels.map((channel) => {
					return <ChannelElement store={props.store}
					                       name={getChannelName(props.guild.channelId2channel[channel.id])}
					                       id={channel.id}
					                       type={props.guild.channelId2channel[channel.id].type}
					                       onDelete={onDeleteChannel}/>
				})}
			</div>
			{props.guild.limits.max_roles_permissions > props.permissions.roles.length &&
                <div className='permission-add-element'>
                    <select ref={refChannelSelect} className='styled-select'>
						{props.availableChannels.map((elem) => {
							return <option value={elem.id}>{getChannelName(elem)}</option>
						})}
                    </select>
                    <button onMouseUp={onAddChannel}>
                        <PlusLogo width='40px' height='40px'/>
                    </button>
                </div>
			}
			<div className='punishment-immune-rule-violations'>
			<span className='permissions-sub-block-title'>
				Нарушения
			</span>
				<ul>
					{listViolationsEnum.map((elem) => {
						// @ts-ignore
						const value: number = (1 << elem[1])
						if ((value & props.immuneRule.violations) !== 0) { // @ts-ignore
							return <DiscordObjectElement store={props.store}
							                             onDelete={onDeleteViolation}
							                             value={value}
							                             icon={undefined}
							                             text={elem[0]}/>
						}
					})}
					{
						avalilableViolations.length > 0 &&
                        <div className='permission-add-element'>
                            <select ref={refViolationSelect} className='styled-select'>
								{avalilableViolations.map((elem) => {
									return <option value={elem[1]}>{elem[0]}</option>
								})}
                            </select>
                            <button onMouseUp={onAddViolation}>
                                <PlusLogo width='30px' height='30px'/>
                            </button>
                        </div>
					}
				</ul>
			</div>

			<div className='permission-delete'>
				<button className='permission-delete-button' onMouseUp={onDelete}>
					<CrossMarkLogo width='20px' height='20px'/>
				</button>
			</div>
		</div>);};

export default observer(PermissionsImmuneRule);
