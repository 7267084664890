import React, { FC } from "react";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";
import Store from "../../store/store";

interface IProps {
	store: Store
	roleName: string
	roleId: string
	updateSettings: (withState: boolean) => Promise<void>;
	onDelete: (userId: any) => Promise<void>;
}


const PermissionRoleItem: FC<IProps> = (props) => {
    return (
		<DiscordObjectElement store={props.store}
		                      onDelete={props.onDelete}
		                      value={props.roleId}
		                      icon={undefined}
		                      text={props.roleName}/>
    );
};

export default PermissionRoleItem;
