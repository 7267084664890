import React, { FC } from "react";
import './style.css'
import { ReactComponent as CrossMarkLogo } from '../../assets/cross-mark.svg';
import { ReactComponent as QuestionLogo } from '../../assets/question.svg';
import Store from "../../store/store";

interface IProps {
	store: Store
	onDelete: (value: any) => Promise<void>;
	value: any
	icon: string | null | undefined
	text: string
}


const DiscordObjectElement: FC<IProps> = (props) => {
	return (
		<div className='discord-obj-element' key={props.value}>
			{props.icon !== undefined &&
				(props.icon
					?
					<img src={props.icon} alt='discord server logo'/>
					:
					<QuestionLogo width='48px' height='48px'/>)
			}
			<span>
				{props.text}
			</span>

			<button onClick={async () => await props.onDelete(props.value)}>
				<CrossMarkLogo color='white' width='20px' height='20px'/>
			</button>
		</div>
	);
};

export default DiscordObjectElement;
