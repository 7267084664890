import {Component, FC, FormEvent, MouseEvent, useRef, useState} from "react";
import './style.css'
import {IMessageSettings} from "../../models/IMessageSettings";
import ViolationEnum from "../../enums/Violation";

interface IProps {
	settings: IMessageSettings
	violation: ViolationEnum
	updateSettings: (withState: boolean) => Promise<void>;
}


const PunishmentSpamFloodSettings: FC<IProps> = (props) => {
	const inputMinRef =  useRef<HTMLInputElement>(null)
	const inputSecRef =  useRef<HTMLInputElement>(null)

	async function onChangeMessagesThreshold(event: FormEvent<HTMLInputElement>) {
		let value: number;
		if (event.currentTarget.value === '') {
			value = 0
		} else {
			value = parseInt(event.currentTarget.value, 10)
			if (Number.isNaN(value)) value = 0;
			if (value > 10) {
				value = 10
			} else if (value < 3) value = 3;
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		if (value !== props.settings.messages_threshold) {
			await props.updateSettings(true);
			props.settings.messages_threshold = value
		}
	}

	async function onChangeMessagesWarningThreshold(event: FormEvent<HTMLInputElement>) {
		let value: number;
		if (event.currentTarget.value === '') {
			value = 0
		} else {
			value = parseInt(event.currentTarget.value, 10)
			if (Number.isNaN(value)) value = 0;
			if (value < 1) value = 0;
			// @ts-ignore
			else if (value > props.settings.messages_threshold - 1) {
				// @ts-ignore
				value = props.settings.messages_threshold - 1
			} else if (value < 2) value = 2;
			else if (value < 1) value = 0;
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		if (value !== props.settings.messages_threshold_warning) {
			await props.updateSettings(true);
			props.settings.messages_threshold_warning = value
		}
	}

	async function onChangeDeleteWarningAfterMin(event: FormEvent<HTMLInputElement>) {
		let value: number;
		let rawValue = event.currentTarget.value

		if (rawValue === '') {
			value = 0
		} else {
			value = parseInt(rawValue, 10)
			if (Number.isNaN(value)) value = 0;
		}

		if (value > 2) {
			value = 2
		} else if (value < 1) {
			value = 0
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		// @ts-ignore
		if (value !== Math.floor(props.settings.delete_warning_after / 60)) {
			// @ts-ignore
			if (value > Math.floor(props.settings.delete_warning_after / 60)) {
				// @ts-ignore
				props.settings.delete_warning_after += (value - Math.floor(props.settings.delete_warning_after / 60)) * 60
			}
			// @ts-ignore
			else if (value < Math.floor(props.settings.delete_warning_after / 60)) {
				// @ts-ignore
				props.settings.delete_warning_after -= (Math.floor(props.settings.delete_warning_after / 60) - value) * 60
			}

			await onChangeDeleteWarningAfter()
		} else if (value.toString() !== rawValue) {
			if (inputMinRef && inputMinRef.current) inputMinRef.current.value = value.toString()
		}
	}

	async function onChangeDeleteWarningAfterSec(event: FormEvent<HTMLInputElement>) {
		let value: number;
		let rawValue = event.currentTarget.value

		if (rawValue === '') {
			value = 0
		} else {
			value = parseInt(rawValue, 10)
			if (Number.isNaN(value)) {
				value = 0
			}

			if (value > 60) {
				value = 59
			} else if (value < 0) {
				value = 0
			}
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		// @ts-ignore
		if (value !== props.settings.delete_warning_after % 60) {
			// @ts-ignore
			if (value > props.settings.delete_warning_after % 60) {
				// @ts-ignoreawait props.updateSettings(true);
				props.settings.delete_warning_after += value - props.settings.delete_warning_after % 60
			} // @ts-ignore
			else if (value < props.settings.delete_warning_after % 60) {
				// @ts-ignore
				props.settings.delete_warning_after -= props.settings.delete_warning_after % 60 - value
			}
			await onChangeDeleteWarningAfter()
		} else if (value.toString() !== rawValue) {
			if (inputSecRef && inputSecRef.current) inputSecRef.current.value = value.toString()
		}
	}

	async function onChangeDeleteWarningAfter() {
		// @ts-ignore
		if (props.settings.delete_warning_after < 0) {
			props.settings.delete_warning_after = 0;
		}
		// @ts-ignore
		else if (props.settings.delete_warning_after > 180) {
			props.settings.delete_warning_after = 180
		}
		await props.updateSettings(true);
		// else {
		// 	// @ts-ignore
		// 	const minutes = Math.floor(props.settings.delete_warning_after / 60)
		// 	if (inputMinRef && inputMinRef.current && inputMinRef.current.value !== minutes.toString()) {
		// 		inputMinRef.current.value = minutes.toString()
		// 	}
		// 	// @ts-ignore
		// 	const seconds = props.settings.delete_warning_after % 60
		// 	if (inputSecRef && inputSecRef.current && inputSecRef.current.value !== seconds.toString()) {
		// 		inputSecRef.current.value = seconds.toString()
		// 	}
		// }
	}

	async function onChangeMessagesInterval(event: FormEvent<HTMLInputElement>) {
		let value: number;
		if (event.currentTarget.value === '') {
			value = 0.0
		} else {
			value = parseFloat(event.currentTarget.value)
			if (Number.isNaN(value)) value = 0.0;
			if (value > 5) {
				value = 5.0
			} else if (value < 0) value = 0.0;
		}

		if (value.toFixed(2) !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		if (value !== props.settings.messages_interval) {
			await props.updateSettings(true);
			props.settings.messages_interval = value
		}

	}

	return (
		<div className='punishment-settings-container'>
			<div className='punishment-settings-block'>
                <span>
                    Порог сообщений
                </span>
				<input defaultValue={props.settings.messages_threshold} maxLength={2} onChange={onChangeMessagesThreshold}/>
			</div>
			<div className='punishment-settings-block'>
                <span>
                    Предупреждать о спаме
                </span>
				<input defaultValue={props.settings.messages_threshold_warning} maxLength={2}
				       onChange={onChangeMessagesWarningThreshold}/>
			</div>
			<div className='punishment-settings-block'>
                <span>
                    Удалять предупреждения после
                </span>
				<p>
					s
				</p>
				{
					// @ts-ignore
					<input defaultValue={props.settings.delete_warning_after % 60}
					       maxLength={2}
					       onChange={onChangeDeleteWarningAfterSec}
					       ref={inputSecRef}
					/>
				}
				<p>
					m
				</p>
				{
					// @ts-ignore

					<input defaultValue={Math.floor(props.settings.delete_warning_after / 60)}
					       maxLength={2}
					       onChange={onChangeDeleteWarningAfterMin}
					       ref={inputMinRef}
					/>
				}

			</div>
			{props.violation === ViolationEnum.flood &&
                <div className='punishment-settings-block'>
                    <span>
                        Интервал между сообщениями
                    </span>
                    <p>с.</p>
                    <input defaultValue={props.settings.messages_interval} maxLength={2}
                           onChange={onChangeMessagesInterval}/>
                </div>
			}
		</div>
	);
};

export default PunishmentSpamFloodSettings;
