import { SVGProps } from "react";
import { ReactComponent as Logo } from "../../assets/user.svg";

interface Props {
  size: number
}

const UserIcon = (props: Props) => {
  return (
    <Logo style={{width: `${props.size}px`, height: `${props.size}px`}}/>
  )
};

export default UserIcon;
