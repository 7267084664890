import React, { FC } from "react";
import './style.css'
import Store from "../../store/store";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";

interface IProps {
	store: Store
	userId: string
	onDelete: (userId: any) => Promise<void>;
}


const UserElement: FC<IProps> = (props) => {
	let text = props.userId;
	let icon: string | null = null;

	for (let i = 0; i < props.store.userList.length; i++) {
		if (props.store.userList[i].id === props.userId) {
			text = props.store.userList[i].username
			if (props.store.userList[i].avatar) {
				icon = `https://cdn.discordapp.com/avatars/${props.userId}/${props.store.userList[i].avatar}.png?size=48`
			}
			break
		}
	}
	return <DiscordObjectElement store={props.store}
							     onDelete={props.onDelete}
	                             value={props.userId}
	                             icon={icon}
	                             text={text}/>
};

export default UserElement;
