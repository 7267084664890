import JSON from "../types/JSON";
import { IGuild } from "../models/IGuild";

export default class guildDto {
    id: string;
    main_settings: JSON;
    verification_settings: JSON;
    welcome_settings: JSON;
    permissions: JSON;
    message_settings: JSON;
    info: JSON

    constructor (model: IGuild) {
        this.id = model.id;
        this.main_settings = model.main_settings;
        this.verification_settings = model.verification_settings
        this.welcome_settings = model.welcome_settings
        this.permissions = model.permissions
        this.message_settings = model.message_settings
        this.info = model.info;
    }

} 
