import React, { FC } from "react";
import './style.css'
import Store from "../../store/store";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";

interface IProps {
	store: Store
	name: string
	id: string
	type: number
	onDelete: (userId: any) => Promise<void>;
}


const ChannelElement: FC<IProps> = (props) => {
	return <DiscordObjectElement store={props.store}
							     onDelete={props.onDelete}
	                             value={{id: props.id, type: props.type}}
	                             icon={undefined}
	                             text={props.name}/>
};

export default ChannelElement;
