import React, {FC, useContext, useState, useEffect} from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import './style.css'
import { LOCALIZATIONS } from '../../services/LocalizationsService';
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import { IPunishment } from '../../models/IPunishment';
import { IPunishmentStage } from '../../models/IPunishmentStage';
import Loader from '../../components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import SendSettings from '../../components/SendSettings/SendSettings';
import { Switch } from "antd";
import SaveSettingsButton from "../../components/SaveSettingsButton/SaveSettingsButton";
import PunishmentStage from "../../components/PunishmentStage/PunishmentStage";
import {IGuildChannel} from "../../models/IGuildChannel";
import {ISendSettings} from "../../models/ISendSettings";
import ViolationEnum from "../../enums/Violation";
import PunishmentSpamFloodSettings from "../../components/PunishmentSpamFloodSettings/PunishmentSpamFloodSettings";
import {IGuild} from "../../models/IGuild";
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import {useLocation} from "react-router-dom";
import PunishmentMentionSpamSettings
	from "../../components/PunishmentMentionSpamSettings/PunishmentMentionSpamSettings";
import {runInAction} from "mobx";
import PunishmentEnum from "../../enums/Punishment";
import {uuid} from "../../utils/uuidUtils";
import GuildList from "../../components/GuildList/GuildList";
import MessagePopUp from "../../components/MessagePopUp/MessagePopUp";
import TextList from "../../components/TextList/TextList";

interface Props {
	name: string
}


const DashboardMessage: FC<Props> = (props) => {
	const { store } = useContext(AuthContext)
	const [updateState, setUpdateState] = useState<boolean>(false);
	const [activeModal, setActiveModal] = useState<boolean>(false)
	const location = useLocation()

	const [mainState, setMainState] = useState<boolean>(store ?
		store.guild ?
			store.guild.message_settings ?
				store.guild.message_settings[props.name] ?
					store.guild.message_settings[props.name].state
					: false
				:false
			: false
		: false);

	const [disabled, setDisabled] = useState<boolean>(!store.guild || !store.user || !store.guild.message_settings || !store.guild.message_settings[props.name] || !mainState);

	store.setDisabledSettings = setDisabled

	useEffect(() => {
	}, []);

	if (store.isLoading) {
		return <Loader text='Получаю данные'/>
	}

	if (store.validateData() || !store.guild || !store.guild.message_settings[props.name]) {
		return store.base_guild_dashboard(location, <ErrorServer text='Не удалось получить данные с сервера'/>)
	}

	const guild: IGuild = store.guild

	const settings = store.guild.message_settings[props.name];

	const availablePunishments: number[] = [];
	for (let i = settings.punishments.length; i < store.guild.limits.max_stages_punishments; i++) {
		availablePunishments.push(0)
	}


	let maxCounterStagePunishment = 0;
	for (let i = 0; i < settings.punishments.length; i++) {
		if (settings.punishments[i].punishments.length > maxCounterStagePunishment) {
			maxCounterStagePunishment = settings.punishments[i].punishments.length
		}
	}

	async function updateSettings(withState: boolean | void = true): Promise<void> {
		if (store.changed) return
		withState && setUpdateState((prev) => !prev);
		store.setChanged(true);
	}

	async function onPunishmentStageAdd() {
		if (disabled) return

		if (settings.punishments.length >= guild.limits.max_stages_punishments) return

		let maxCount = 0;
		for (let i = 0; i < settings.punishments.length; i++) {
			if (settings.punishments[i].count > maxCount) {
				maxCount = settings.punishments[i].count
			}
		}
		if (maxCount < 20) {
			maxCount += 1;
		} else {
			for (let i = maxCount; i >= 0; i--) {
				if (settings.punishments[i] === undefined) {
					maxCount = i + 1;
					break
				}
			}
		}

		runInAction(() => {
			settings.punishments.push({
				'count': maxCount,
				'delete_message': true,
				'punishments': [{'punishment': PunishmentEnum.warn.valueOf(), 'duration': 60}] as IPunishment[],
				'id': uuid()
			} as IPunishmentStage)
		})

		await updateSettings(true)
	}

	async function onSendSettingsAdd() {
		if (disabled) return

		if (Object.keys(settings.send_settings).length >= guild.limits.max_log_channels_stage_punishment) return
		settings.send_settings[availableChannels[0].id.toString()] = {state: true,
			forgot_text: 0,
			show_text: false,
			delete_after: 0} as ISendSettings
		await updateSettings(true)

	}

	async function changeMainState() {
		setMainState((prev) => {
			settings.state = !prev;
			updateSettings(false); // false - потому, что уже задаем стейт для мейна
			return !prev
		})

	}

	let availableChannels: IGuildChannel[] = store.guild.channels.slice()

	availableChannels = availableChannels.filter((elem) => {
		return elem.type !== 4
	})

	if (props.name !== 'hacked_account') {
		for (const [key, value] of Object.entries(settings.send_settings)) {
			for (let i = 0; i < availableChannels.length; i++) {
				if (availableChannels[i].id === key) {
					availableChannels.splice(i, 1)
					break
				}
			}
		}
	} else {
		for (const [key, value] of Object.entries(settings.send_settings)) {
			for (let i = 0; i < availableChannels.length; i++) {
				if (availableChannels[i].id === key || availableChannels[i].id === 'public' || availableChannels[i].id === 'dm') {
					availableChannels.splice(i, 1)
					break
				}
			}
		}
	}

	const violation_type = ViolationEnum[props.name as keyof typeof ViolationEnum]

	let violationList: string[] = [];
	let violationListLimit = 0;
	let violationListElement: React.ReactNode | null = null;

	let violationListName;
	if (violation_type === ViolationEnum.file) {
		// @ts-ignore
		violationList = settings.whitelist
		violationListName = 'Белый список'
		violationListLimit = store.guild.limits.whitelist_files
		violationListElement = <TextList store={store}
		                                 placeholder='Хэш SHA256 файла или тип файла (.exe)'
		                                 updateSettings={updateSettings}
		                                 limit={violationListLimit}
		                                 list={violationList}
		                                 isOwner={true}
										 padding={true}/>
	} else if (violation_type === ViolationEnum.blacklist_file) {
		// @ts-ignore
		violationList = settings.list
		violationListName = 'Черный список'
		violationListLimit = store.guild.limits.blacklist_files
		violationListElement = <TextList store={store}
										 placeholder='Хэш SHA256 файла или тип файла (.exe)'
		                                 updateSettings={updateSettings}
		                                 limit={violationListLimit}
		                                 list={violationList}
		                                 isOwner={true}
		                                 padding={true}/>
	} else if (violation_type === ViolationEnum.blacklist_url) {
		// @ts-ignore
		violationList = settings.list
		violationListName = 'Черный список'
		violationListLimit = store.guild.limits.blacklist_urls
		violationListElement = <TextList store={store}
		                                 placeholder='Домен, ссылка'
		                                 updateSettings={updateSettings}
		                                 limit={violationListLimit}
		                                 list={violationList}
		                                 isOwner={true}
		                                 padding={true}/>
	} else if (violation_type === ViolationEnum.blacklist_word) {
		// @ts-ignore
		violationList = settings.list
		violationListName = 'Черный список'
		violationListLimit = store.guild.limits.blacklist_words
		violationListElement = <TextList store={store}
		                                 placeholder='Слово, предложение'
		                                 updateSettings={updateSettings}
		                                 limit={violationListLimit}
		                                 list={violationList}
		                                 isOwner={true}
		                                 padding={true}/>

	} else if (violation_type === ViolationEnum.discord_invite) {
		// @ts-ignore
		violationList = settings.whitelist
		violationListName = 'Белый список'
		violationListLimit = 10
		violationListElement = <GuildList store={store}
		                                  updateSettings={updateSettings}
		                                  limit={violationListLimit}
		                                  list={violationList}
		                                  isOwner={true}
										  padding={true}/>
	}
	let additionalSettingsFlag = violation_type === ViolationEnum.spam || violation_type === ViolationEnum.flood || violation_type === ViolationEnum.mention_spam;

	const master_state =  guild.message_settings.master.state && props.name !== 'master' && mainState

	return (
		<section className='settings-section'>
			{/* <MessagePopUp text='Сервер не найден, возможно бот не присутсвует на сервере. Вы можете оставить '/> */}
			<div className='settings-header'>
				<div className='settings-header-title'>
					<h2 style={{borderBottom: '3px solid ' + (mainState ? 'green' : 'red')}}>
						{LOCALIZATIONS.violations[props.name].name}
					</h2>
					<div className='settings-header-switch'>
						<Switch checked={mainState} onChange={changeMainState}/>
					</div>
				</div>
				{ LOCALIZATIONS.violations[props.name].description &&
					LOCALIZATIONS.violations[props.name].description.length > 0 &&
					<div className='settings-header-description'>
						<span>
							{LOCALIZATIONS.violations[props.name].description}
						</span>
					</div>
				}

			</div>

			<div className='settings-block'
			     style={{'filter': mainState ? 'none' : 'blur(10px)',
				         'pointerEvents': mainState ? 'all' : 'none',
			              'cursor': mainState ? 'default' : 'not-allowed'}}>
				{
					additionalSettingsFlag &&
                    <div className='settings-block-header' style={{marginTop: '15px'}}>
                        <span>
                            Дополнительные настройки
                        </span>
                    </div>
				}
				{
					(violation_type === ViolationEnum.spam || violation_type === ViolationEnum.flood) &&
                    <PunishmentSpamFloodSettings settings={settings} violation={violation_type} updateSettings={updateSettings}/>
				}
				{
					(violation_type === ViolationEnum.mention_spam) &&
                    <PunishmentMentionSpamSettings settings={settings} updateSettings={updateSettings}/>
				}

				<div className='settings-block-header' style={{marginTop: '15px'}}>
                    <span>
                        Наказания
                    </span>
				</div>


				<div className='grid-punishments-container'>
					{
						master_state && <div className='disable-master'>
							<span>
								Для редактирования отключите "Мастер настройка"
							</span>
                        </div>
					}
					<div className='grid-punishments'
					     style={master_state ? {'filter': 'blur(10px)', 'pointerEvents': 'none', 'cursor': 'not-allowed'} : {}}>
						{
							settings.punishments.slice().sort((a, b) => a.count - b.count).map((punishment_stage_settings, punishment_stage_idx) => {
								return <PunishmentStage
									key={punishment_stage_settings.id}
									guild={guild}
									violation={props.name}
									punishment_stage={punishment_stage_settings}
									message_settings={settings}
									punishment_stage_idx={punishment_stage_idx}
									updateSettings={updateSettings}/>
							})
						}
						{
							settings.punishments.length < store.guild.limits.max_stages_punishments &&
							availablePunishments.map(() => {
								return (
									<div className='punishment-stage-container' key={uuid()}>
										<div className='punishment-add-stage'>
											<button onMouseUp={onPunishmentStageAdd}>
												<PlusLogo width='50px' height='50px'/>
											</button>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>

				<div className='settings-block-header'>
                    <span>
                        Логирование
                    </span>
				</div>



				<div className='grid-send-settings-container'>
					{
						master_state && <div className='disable-master'>
							<span>
								Для редактирования отключите "Мастер настройка"
							</span>
                        </div>
					}
					<div className='grid-send-settings' style={master_state ? {'filter': 'blur(10px)', 'pointerEvents': 'none', 'cursor': 'not-allowed'} : {}}>
						{Object.entries(settings.send_settings).map(([key, channel]) => [
							<SendSettings
										key={key}
										violation={props.name}
										availableChannels={availableChannels}
										channel={channel}
										channel_id={key}
										send_settings={settings.send_settings} guildChannels={guild.channels}
										updateSettings={updateSettings}/>
						])}


						{Object.keys(settings.send_settings).length < store.guild.limits.max_log_channels_stage_punishment &&
							availableChannels.length > 0 &&
							Array.from({length: store.guild.limits.max_log_channels_stage_punishment - Object.keys(settings.send_settings).length}).map(() => {
								return <div className='send-settings' key={uuid()}>
											<div className='send-settings-add'>
												<button onMouseUp={onSendSettingsAdd}>
													<PlusLogo width='50px' height='50px'/>
												</button>
											</div>
										</div>
							})}
					</div>
				</div>

				<div className='settings-block-header' style={{marginTop: '15px'}}>
                    <span>
                        {violationListName}
                    </span>
				</div>

				{violationListElement && violationListElement}
			</div>

			{store.changed && <SaveSettingsButton setDisabled={setDisabled}/>}
		</section>
	);
};

export default observer(DashboardMessage);