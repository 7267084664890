import {FC, FormEvent, MouseEvent, RefObject} from "react";
import './style.css'
import {IPunishmentStage} from "../../models/IPunishmentStage";


interface IProps {
    updateSettings: (withState: boolean) => Promise<void>;
    punishment_stage: IPunishmentStage
    counts: number[]
}


const PunishmentStageCounterSelector: FC<IProps> = (props) => {
    async function onRight(event: MouseEvent<HTMLButtonElement>) {
        if (props.counts.includes(props.punishment_stage.count + 1)) {
            for (let i = props.punishment_stage.count + 1; i <= 20; i++) {
                if (props.counts.includes(i)) {
                    continue;
                }
                props.punishment_stage.count = i
                // props.stage_container_ref.current?.classList.toggle('attention')
                //
                // new Promise((resolve, reject) => {
                //   setTimeout(() => {
                //     props.stage_container_ref.current?.classList.toggle('attention')
                //   }, 200)
                // });
                break
            }
        } else {
            props.punishment_stage.count += 1
        }
        await props.updateSettings(true)
    }

    async function onLeft(event: MouseEvent<HTMLButtonElement>) {
        if (props.counts.includes(props.punishment_stage.count - 1)) {
            for (let i = 0; i <= props.punishment_stage.count-1; i++) {
                if (props.counts.includes(i)) {
                    continue;
                }
                props.punishment_stage.count = i
                break
            }
        } else {
            props.punishment_stage.count -= 1
        }
        await props.updateSettings(true)
    }
    return (
        <div className='punishment-stage-counter-selector'>
            <button className='left-button' onMouseUp={onLeft} disabled={props.punishment_stage.count <= 0}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth="48" d="m184 112 144 144-144 144"></path>
                </svg>
            </button>
            <span>{props.punishment_stage.count}</span>
            <button className='right-button' onMouseUp={onRight} disabled={props.punishment_stage.count >= 20}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinejoin="round"
                          strokeWidth="48" d="m184 112 144 144-144 144"></path>
                </svg>
            </button>
        </div>
    );
};

export default PunishmentStageCounterSelector;
