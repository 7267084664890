import React, { FC } from 'react';
import './style.scss'

const NotFound: FC = () => {

    return (
        <div>
            <h2>Не найдено</h2>
            <button onClick={() => window.location.href = '/'}>Вернуться домой</button>
        </div>
  );
}

export default NotFound;