import { createRoot } from 'react-dom/client';
import './index.scss'
import App from './App';

import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <QueryClientProvider client={queryClient}>
        <App/>
    </QueryClientProvider>
);
