import React, { FC, MouseEvent, useRef } from 'react';
import './style.css'
import { IPermissions } from '../../models/IPermissions';
import PermissionEnum from "../../enums/Permission";
import PermissionRoleItem from "../PermissionRoleItem/PermissionRoleItem";
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import {IGuild} from "../../models/IGuild";
import {IGuildRole} from "../../models/IGuildRole";
import { ReactComponent as CrossMarkLogo } from '../../assets/cross-mark.svg';
import {IGuildChannel} from "../../models/IGuildChannel";
import PermissionsLevelSelector from "../PermissionsLevelSelector/PermissionsLevelSelector";
import Store from "../../store/store";
import UserList from "../UserList/UsersList";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";
import {observer} from "mobx-react-lite";
import PermissionsImmuneRule from "../PermissionsImmuneRule/PermissionsImmuneRule";
import {IImmuneRule} from "../../models/IImmuneRule";
import {uuid} from "../../utils/uuidUtils";
import {runInAction} from "mobx";

interface IProps {
	store: Store
	guild: IGuild;
	permissions: IPermissions;
	updateSettings: (withState: boolean) => Promise<void>;
}

const Permissions: FC<IProps> = (props) => {
	const permissions = props.permissions;
	const refPermissionSelect = useRef<HTMLSelectElement>(null)
	const refRoleSelect = useRef<HTMLSelectElement>(null)

	let avalilablePermissions = []
	
	const listPermissionsEnum: [string, string | PermissionEnum][] = Object.entries(PermissionEnum).filter((k, _) => typeof k[1] === 'number')

	// добавляем доступные права
	for (let i = 0; i < listPermissionsEnum.length; i++) {
		 // @ts-ignore
		((listPermissionsEnum[i][1] & permissions.permissions) === 0) && avalilablePermissions.push(listPermissionsEnum[i])
	}
	
	
	let avalilableRoles: IGuildRole[] = []
	if (props.guild.limits.max_roles_permissions > permissions.roles.length) {
		for (let i = 0; i < props.guild.roles.length; i++) {
			if (!permissions.roles.includes(props.guild.roles[i].id))
				avalilableRoles.push(props.guild.roles[i])
		}
	}
	
	
	async function onAddPermission(event: MouseEvent<HTMLButtonElement>) {
		if (refPermissionSelect === null || refPermissionSelect.current === null) return

		const value = parseInt(refPermissionSelect.current.value, 10)
		if (Number.isNaN(value)) return
		props.permissions.permissions = props.permissions.permissions | value
		await props.updateSettings(true)
	}
	
	async function onAddRole(event: MouseEvent<HTMLButtonElement>) {
		if (refRoleSelect === null || refRoleSelect.current === null) return
		
		props.permissions.roles.push(refRoleSelect.current.value)
		
		await props.updateSettings(true)
	}


	async function onAddImmuneRule() {
		props.permissions.immune_rules.push({
											violations: 0,
											channels: [],
											id: uuid()} as IImmuneRule)
		// props.permissions.white_violation_channels[refViolationChannelSelect.current.value] = {
		// 	value: 0,
		// 	type: type === 4 ? 1 : 0 // category type - 4
		// }

		await props.updateSettings(true)
	}
	
	async function onDelete(event: MouseEvent<HTMLButtonElement>) {
		for (let i = 0; i < props.guild.permissions.length; i++) {
			if (props.permissions.id === props.guild.permissions[i].id) {
				runInAction(() => {
					props.guild.permissions.splice(i, 1)
				})
				await props.updateSettings(true)
				break
			}
		}
    }
	
	let availableChannels: IGuildChannel[] = props.guild.channels.slice()

	availableChannels = availableChannels.filter((elem) => {
		for (let i = 0; i < permissions.immune_rules.length; i++) {
			for (let j = 0; j < permissions.immune_rules[i].channels.length; j++) {
				if (elem.id === permissions.immune_rules[i].channels[j].id) {
					console.log(elem.name, 'false')
					return false
				}
			}
			return true
		}
		return true
	})


	async function onRoleDelete(roleId: string) {
		const index = props.permissions.roles.indexOf(roleId)
		if (index === -1) return
		runInAction(() => {
			props.permissions.roles.splice(index, 1)
		})
		await props.updateSettings(true)
	}

	async function onPermissionDelete(permission: number) {
		props.permissions.permissions = props.permissions.permissions & (~permission)
		await props.updateSettings(true)
	}

	return (
		<div className='permissions-container'>
			<div className='permissions-header'>
				<span>Уровень доступа</span>
				<PermissionsLevelSelector updateSettings={props.updateSettings}
										  permissions={props.permissions}
										  permissions_list={props.guild.permissions}/>
			</div>
			<div className='permission-block' id='perms'>
				<span className='permissions-block-title'>
					Права
				</span>

				<ul>
					{listPermissionsEnum.map((elem) => {
						// @ts-ignore
						const value: number = elem[1]
						if ((value & permissions.permissions) !== 0) { // @ts-ignore
							return <DiscordObjectElement store={props.store}
							                             onDelete={onPermissionDelete}
							                             value={value}
							                             icon={undefined}
							                             text={elem[0]}/>
						}
					})}
					{
						avalilablePermissions.length > 0 &&
						<div className='permission-add-element'>
							<select ref={refPermissionSelect} className='styled-select'>
								{avalilablePermissions.map((elem) => {
									return <option value={elem[1]}>{elem[0]}</option>
								})}
							</select>
							<button onMouseUp={onAddPermission}>
								<PlusLogo width='40px' height='40px'/>
							</button>
						</div>
					}
				</ul>
			</div>

			<div className='permission-block' id='users'>
				<span className='permissions-block-title'>
					Пользователи
				</span>

				<UserList store={props.store}
				          updateSettings={props.updateSettings}
				          list={permissions.users}
				          limit={props.guild.limits.max_users_permissions}
				          isOwner={true}
				          padding={false}/>
			</div>

			<div className='permission-block' id='roles'>
				<span className='permissions-block-title'>
					Роли
				</span>

				{/*<TextList store={props.store}/>*/}

				<ul>
					{permissions.roles.map((elem) => {
						return <PermissionRoleItem store={props.store}
						                           roleId={elem}
						                           roleName={props.guild.roleId2role[elem.toString()].name}
												   onDelete={onRoleDelete}
						                           updateSettings={props.updateSettings}/>
					})}
					{props.guild.limits.max_roles_permissions > permissions.roles.length &&
                        <div className='permission-add-element'>
                            <select ref={refRoleSelect} className='styled-select'>
								{avalilableRoles.map((elem) => {
									return <option value={elem.id}>{elem.name}</option>
								})}
                            </select>
                            <button onMouseUp={onAddRole}>
                                <PlusLogo width='40px' height='40px'/>
                            </button>
                        </div>}
				</ul>
			</div>

			<div className='permission-block' id='immune'>
				<span className='permissions-block-title'>
					Иммунитет
				</span>

				<ul className='permissions-immune-rules'>
					{permissions.immune_rules.map((rule) => {
						return <PermissionsImmuneRule store={props.store}
						                              guild={props.guild}
						                              permissions={props.permissions}
						                              immuneRule={rule}
						                              availableChannels={availableChannels}
						                              updateSettings={props.updateSettings}/>
					})}
				</ul>
				<div className='permission-add-element'>
					<button onMouseUp={onAddImmuneRule}>
						<PlusLogo width='40px' height='40px'/>
					</button>
				</div>
			</div>

			<div className='permission-delete'>
				<button className='permission-delete-button' onMouseUp={onDelete}>
					<CrossMarkLogo width='20px' height='20px'/>
				</button>
			</div>
		</div>
	);
}
export default observer(Permissions);