import React, {FC, useRef, ReactNode, useLayoutEffect, RefObject} from "react";
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import Store from "../../store/store";
import {observer} from "mobx-react-lite";


interface IProps {
	store: Store
	updateSettings: (withState: boolean) => Promise<void>;
	onAdd: (value: any, ref: HTMLInputElement) => Promise<any>;
	onDelete: (value: any) => Promise<any>
	getElement: (value: any) => ReactNode;
	list: string[]
	limit: number
	placeholder: string
	isOwner: boolean
	padding: boolean;
}


const DiscordElementList: FC<IProps> = (props)    => {
	const ref = useRef<HTMLInputElement>(null)

	async function onAdd() {
		if (!props.isOwner || !ref || !ref.current || !ref.current.value ||  ref.current.value.length === 0 ||
			props.list.length >= props.limit) return

		await props.onAdd(ref.current.value, ref.current)
	}

	async function onDelete(value: string) {
		if (!props.isOwner) return

		await props.onDelete(value)
	}

	useLayoutEffect(() => {
		let input = document.querySelectorAll('input');
		for (let i=0; i < input.length; i++) {
			if (input[i].getAttribute('placeholder') !== null) {
				// @ts-ignore
				input[i].setAttribute('size', input[i].getAttribute('placeholder').length);
			}
		}
	}, []);

	return (
		<div className={'discord-element-list' + (props.padding ? ' padding' : '')}>
			<ul>
				{
					props.list.map((elem) => {
						return props.getElement({'store': props.store,
												 'onDelete': onDelete,
												 'value': elem})
					})
				}
				{
					props.limit > props.list.length &&
                    <div className='discord-element-list-add-element'>
                        <input ref={ref}
                               onKeyDown={async (e) => {
							       if (e.key === "Enter") {
								       await onAdd()
							       }
						       }}
                               onInput={async (e) => {
	                               e.currentTarget.style.width = e.currentTarget.value.length + "ch";
                               }}
                               placeholder={props.placeholder}/>
                        <button onMouseUp={onAdd}>
                            <PlusLogo width='40px' height='40px'/>
                        </button>
                    </div>
				}
			</ul>
		</div>
	)
};

export default observer(DiscordElementList);
