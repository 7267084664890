import React, { FC, useContext, useEffect, useState, useCallback, useLayoutEffect, Component, SetStateAction } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import {useLocation, useNavigate} from 'react-router-dom';
import './style.css'
import { LOCALIZATIONS } from '../../services/LocalizationsService';
import Permission from '../../components/Permissions/Permissions';
import Loader from "../../components/Loader/Loader";
import { observer } from 'mobx-react-lite';
import updateSettings from "../../types/updateSettings";
import SaveSettingsButton from "../../components/SaveSettingsButton/SaveSettingsButton";
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import {IPunishment} from "../../models/IPunishment";
import {IPermissions} from "../../models/IPermissions";
import {IGuild} from "../../models/IGuild";
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import {uuid} from "../../utils/uuidUtils";
import {runInAction} from "mobx";
// import { updateKeyByPath, removeKeyByPath } from '../../utils/dictUtils'


const DashboardPermission: FC = () => {
    const { store } = useContext(AuthContext)
    const [updateState, setUpdateState] = useState<boolean>(false);

    const location = useLocation()

    const [disabled, setDisabled] = useState<boolean>(store.guild === null || store.user === null);
    store.setDisabledSettings = setDisabled

    useEffect(() => {
    }, []);


    if (store.isLoading) {
        return <Loader text='Получаю данные'/>
    }

    if (store.validateData()) {
        return store.base_guild_dashboard(location, <ErrorServer text='Не удалось получить данные с сервера'/>)
    }

    // @ts-ignore
    const guild: IGuild = store.guild

    async function updateSettings(withState: boolean | void = true): Promise<void> {
        if (store.changed) return
        withState && setUpdateState((prev) => !prev);
        store.setChanged(true);
    }
    
    
    async function onAdd(): Promise<void> {
        let max_level = 0;
        for (let i = 0; i < guild.permissions.length; i++) {
            if (guild.permissions[i].level > max_level) {
                max_level = guild.permissions[i].level
            }
        }
        runInAction(() => {
            guild.permissions.push({
                level: max_level + 1,
                permissions: 0,
                roles: [],
                users: [],
                immune_rules: [],
                id: uuid()
            } as IPermissions)
        })
        await updateSettings(true)
    }

    let permissions = guild.permissions;

    const availablePermissions: number[] = [];
    for (let i = guild.permissions.length; i < guild.limits.max_number_permissions; i++) {
        availablePermissions.push(0)
    }
    
    return (
        <section className='settings-section'>
            <div className='settings-block'>
                <div className='settings-block-header' style={{marginTop: '15px'}}>
                    <span>
                        Права доступа
                    </span>
                </div>
                
                <div className='grid-permissions'>
                    {
                        permissions.slice().sort((a, b) => a.level - b.level).map((elem) => {
                            return <Permission store={store}
                                               guild={guild}
                                               permissions={elem}
                                               updateSettings={updateSettings}/>
                        })
                    }
                    {
                        availablePermissions.map(_ => {
                            return (<div className='permissions-container'>
                                <div className='permission-add'>
                                    <button onMouseUp={onAdd}>
                                        <PlusLogo width='50px' height='50px'/>
                                    </button>
                                </div>
                            </div>)
                        })
                    }
                </div>
            </div>
            {store.changed && <SaveSettingsButton setDisabled={setDisabled}/>}
        </section>
    );
}

export default observer(DashboardPermission);