import React, { FC, ReactNode } from "react";
import './style.css'
import Store from "../../store/store";
import UserElement from "../UserElement/UserElement";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";
import DiscordElementList from "../DiscordElementList/DiscordElementList";

interface IProps {
	store: Store
	updateSettings: (withState: boolean) => Promise<void>;
	list: string[]
	limit: number
	isOwner: boolean
	padding: boolean
}


const UserList: FC<IProps> = (props)    => {
	async function onAdd(rawValue: string, ref: HTMLInputElement) {
		let match = rawValue.match(/^\d{15,20}$/g)
		if (!match || match.length === 0) return
		let value = match[0]

		if (props.list.includes(value)) {
			ref.value = 'Уже в списке'
			return
		}
		const user = await props.store.fetchDiscordUser(value)
		if (!user) {
			const elements: any = document.querySelectorAll('.discord-element-list-add-element input')
			if (elements) {
				elements[0].value = 'Пользователь не найден'
			}
			return
		}
		runInAction(() => {
			props.list.push(value)
		})
		await props.updateSettings(true)
	}

	async function onDelete(userId: string) {
		const index = props.list.indexOf(userId)
		if (index === -1) return

		runInAction(() => {
			props.list.splice(index, 1)
		})


		for (let i = 0; i < props.store.userList.length; i++) {
			if (props.store.userList[i].id === userId) {
				runInAction(() => {
					props.store.userList.splice(i, 1)
				})
				props.store.setUserList()
				break;
			}
		}
		await props.updateSettings(true);
	}

	function getElement(value: any): ReactNode {
		return <UserElement
			key={value}
			store={value.store}
			onDelete={value.onDelete}
			userId={value.value}
		/>
	}

	return (
		<DiscordElementList store={props.store}
		                    updateSettings={props.updateSettings}
		                    onAdd={onAdd}
		                    onDelete={onDelete}
		                    getElement={getElement}
		                    list={props.list}
		                    limit={props.limit}
		                    placeholder='ID пользователя'
		                    isOwner={props.isOwner}
		                    padding={props.padding}
		/>
)
};

export default observer(UserList);
