import React, { FC, useEffect, useContext, useState, Component } from 'react';
import Guild from '../../components/Guild/Guild'
import './style.css'
import {IGuild, IGuildIcon} from "../../models/IGuild";
import { observer } from 'mobx-react-lite';
import Loader from '../../components/Loader/Loader';
import { AuthContext } from '../../providers/AuthProvider';
import {redirect, Navigate, useNavigate} from "react-router-dom";
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import RainbowButton from "../../components/RainbowButton/RainbowButton";


const SelectGuild: FC = () => {
    const { store } = useContext(AuthContext)
    // const [guilds, setGuilds] = useState<IGuildIcon[] | null>(null)
    const navigate = useNavigate();

    const fetch = async () => {
        await store.checkAuth();

        if (!store.isAuth) {
            store.login(navigate)
            return
        }
        await store.getGuilds()
    }

    useEffect( () => {
        // @ts-ignore
        if (localStorage.getItem('token')) fetch();
        else store.login(navigate)

    }, [])

    // if (location.pathname !== '/guilds' && location.pathname !== "/guilds/") {
    //     return <Navigate to='/guilds' replace={true}/>
    // }

    if (store.isLoading) return <Loader text='Получаю сервера'/>


    async function onAdd() {
        // navigate('https://discord.com/oauth2/authorize?client_id=1219270781851402350',
        //     {replace: true, relative: 'route'})
        window.location.href = 'https://discord.com/oauth2/authorize?client_id=1219270781851402350'
        // return navigate('https://discord.com/oauth2/authorize?client_id=1219270781851402350')
    }

    return (
        <section className='settings-section'>
            {/*<div className='add-bot-container'>*/}
            {/*    <button className='add-bot-button' onMouseUp={onAdd}>*/}
            {/*        <div className='add-bot-text'>*/}
            {/*            Добавить бота на сервер*/}
            {/*        </div>*/}
            {/*    </button>*/}
            {/*</div>*/}
            <RainbowButton text='Добавить бота на сервер' onClick={onAdd}/>
            {
                store.user_guilds === null ?
                <ErrorServer text='Не удалось получить список серверов'/>

                    :

                store.user_guilds.length === 0 ?
                <ErrorServer text='У вас нет серверов на которых состоит бот'/>

                    :

                <div className="grid-parent">
                    {store.user_guilds?.map((element: IGuildIcon) => {
                        return <Guild key={element.id}
                                      id={element.id}
                                      name={element.name}
                                      icon_url={element.icon_url}/>
                })}
            </div>
            }
        </section>

    );
};

export default observer(SelectGuild);