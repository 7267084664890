import UserIcon from '../UserIcon/UserIcon';

import React, {FC, useContext} from 'react';

import { AuthContext } from '../../providers/AuthProvider';

import './style.scss'
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const MenuUserButton: FC = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { store } = useContext(AuthContext)
    const navigate = useNavigate();

    const handleClick = async (e: any, from:boolean) => {
        if (!from) {
            setIsOpen((prev) => {
                let elements = document.getElementsByClassName('user-menu-list')
                if (elements) {
                    if (prev) {
                        elements[0].classList.remove('active');
                        elements[0].classList.add('inactive');
                    } else {
                        elements[0].classList.remove('inactive');
                        elements[0].classList.add('active');
                    }
                }
                return !prev
            });
        } else {

        }
    };

    const addMap = async (id: string) => {
        switch(id) {
            case "logout":
                await store.logout(navigate);
                break;
            case "guilds":
                store.guilds(navigate);
                // navigate('/guilds');
                break;
            case "login":
                store.login(navigate);
                break;
        }
    };

    let itemsMenu;
    if (store.isAuth) {
        itemsMenu = [{'path': 'guilds', 'title': 'Guilds'},
            {'path': 'logout', 'title': 'Logout'},
            {'path': 'profile', 'title': 'Profile'}];
    } else {
        itemsMenu = [{'path': 'login', 'title': 'Login'}];
    }

    return (
        <div className='user-menu-container' onClick={async (e) => await handleClick(e, true)}>
            <div className='user-menu'>
                <button onClick={async (e) => await handleClick(e, false)}>
                    <UserIcon size={50}/>
                </button>
                <div className='user-menu-list'>
                    <ul>
                        {itemsMenu.map(elem => {
                            return (
                                <li>
                                    <button onClick={async () => addMap(elem.path)}>{elem.title}</button>
                                </li>)
                        })}

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default observer(MenuUserButton)