enum ViolationEnum {
    // other = 0,
    file = 1,
    hacked_account = 2,
    spam_nn = 3,
    spam = 4,
    flood = 5,
    hyperlink = 6,
    discord_invite = 7,
    scam_url = 8,
    pirate_url = 9,
    adult_url = 10,
    casino_url = 11,
    http_url = 12,
    blacklist_word = 13,
    blacklist_url = 14,
    blacklist_file = 15,
    mention_spam = 16,
    invisible_mention = 17
}

export default ViolationEnum