import React, {FC, useContext, useState, MouseEvent, FormEvent, useEffect} from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import './style.css'
import Loader from '../../components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import {useLocation} from "react-router-dom";

const DashboardLogs: FC = () => {
    const { store } = useContext(AuthContext)
	const location = useLocation()
	const [updateState, setUpdateState] = useState<boolean>(false);

    async function updateSettings(withState: boolean | void = true): Promise<void> {
        if (store.changed) return
        withState && setUpdateState((prev) => !prev);
        store.setChanged(true);
    }

    useEffect(() => {
    }, []);

    if (store.isLoading) {
        return <Loader text='Получаю данные'/>
    }

    if (store.validateData()) {
        return store.base_guild_dashboard(location, <ErrorServer text='Не удалось получить данные с сервера'/>)
    }

    return (
        <div className='indevelopment'>
            В разработке
        </div>
    );
};

export default observer(DashboardLogs);