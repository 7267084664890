import { useContext, FC } from 'react';
import './style.css'
import { AuthContext } from '../../providers/AuthProvider';
import { ReactComponent as DiscordLogo } from '../../assets/discord.svg';

const Login: FC = () => {
  const { store } = useContext(AuthContext)

  return (
    <form>
      <DiscordLogo className='login_discord'/>
      <button className='login_button' onClick={store.login}>
        <span>Войти</span>
      </button>
    </form>
    
  );
}

export default Login;