import './style.scss'
import { IGuildIcon } from '../../models/IGuild'
import { ReactComponent as QuestionLogo } from '../../assets/question.svg';
import {useNavigate} from "react-router-dom";


const Guild = (guild: IGuildIcon) => {
    const navigate = useNavigate()

    return (
        <button className='guild-container' onClick={() => navigate(`/guilds/${guild.id}/`)}>
            {guild.icon_url ? <img className='guild-img' id={`guild-img-${guild.id}`} src={guild.icon_url}
                                    alt='discord server logo'/>
                            :
                            <QuestionLogo/>
            }
            
            <div className='guild-name'>
                <p>
                    {guild.name}
                </p>
            </div>
        </button>
    );
}

export default Guild;
 
