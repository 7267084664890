import React, { FC  } from 'react';
import './style.css'
import { observer } from 'mobx-react-lite';


interface IProps {
	text: string
	onClick: () => any;
}

const RainbowButton: FC<IProps> = (props) => {

	return (
			<div className='rainbow-button-container'>
				<button className='rainbow-button-button' onMouseUp={props.onClick}>
					<div className='rainbow-button-text'>
						Добавить бота на сервер
					</div>
				</button>
			</div>
	);
};

export default observer(RainbowButton);