import { FC, useEffect, useContext } from 'react';
import LoginComponent from '../../components/Login/Login';

const Login: FC = () => {
  return (
    <>
        <LoginComponent/>
    </>
    
  );
};

export default Login;