import React, {FC} from "react";
import { motion } from "framer-motion";
import './style.css'

const spinTransition = {
	loop: 9999,
	repeat: Infinity,
	ease: "linear",
	duration: 1
};

interface IProps {
	text: string
}

const Loader: FC<IProps> = (props) => {
	// @ts-ignore
	return (
		<div className="loader">
			<div>
				<motion.span className="circle" animate={{rotate: 360}}
							 transition={spinTransition}/>
			</div>
			{props.text && <span>
				{props.text}
			</span>}
		</div>
	);
};

export default Loader;
