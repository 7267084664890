import {Component, FC, FormEvent, MouseEvent, useRef, useState} from "react";
import './style.css'
import {IMessageSettings} from "../../models/IMessageSettings";
import ViolationEnum from "../../enums/Violation";

interface IProps {
	settings: IMessageSettings
	updateSettings: (withState: boolean) => Promise<void>;
}


const PunishmentMentionSpamSettings: FC<IProps> = (props) => {
	const inputMinRef =  useRef<HTMLInputElement>(null)
	const inputSecRef =  useRef<HTMLInputElement>(null)

	async function onChangeMentionsThreshold(event: FormEvent<HTMLInputElement>) {
		let value: number;
		if (event.currentTarget.value === '') {
			value = 0
		} else {
			value = parseInt(event.currentTarget.value, 10)
			if (Number.isNaN(value)) value = 0;
			if (value > 10) {
				value = 10
			} else if (value < 3) value = 3;
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		if (value !== props.settings.mentions_threshold) {
			await props.updateSettings(true);
			props.settings.mentions_threshold = value
		}
	}

	async function onChangeMentionsWarningThreshold(event: FormEvent<HTMLInputElement>) {
		let value: number;
		if (event.currentTarget.value === '') {
			value = 0
		} else {
			value = parseInt(event.currentTarget.value, 10)
			if (Number.isNaN(value)) value = 0;
			if (value < 1) value = 0;
			// @ts-ignore
			else if (value > props.settings.mentions_threshold - 1) {
				// @ts-ignore
				value = props.settings.mentions_threshold - 1
			} else if (value < 2) {
				value = 2;
			} else if (value < 1) value = 0;
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		if (value !== props.settings.mentions_threshold_warning) {
			await props.updateSettings(true);
			props.settings.mentions_threshold_warning = value
		}
	}

	async function onChangeDeleteWarningAfterMin(event: FormEvent<HTMLInputElement>) {
		let value: number;
		let rawValue = event.currentTarget.value

		if (rawValue === '') {
			value = 0
		} else {
			value = parseInt(rawValue, 10)
			if (Number.isNaN(value)) value = 0;
		}

		if (value > 2) {
			value = 2
		} else if (value < 1) {
			value = 0
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		// @ts-ignore
		if (value !== Math.floor(props.settings.delete_warning_after / 60)) {
			// @ts-ignore
			if (value > Math.floor(props.settings.delete_warning_after / 60)) {
				// @ts-ignore
				props.settings.delete_warning_after += (value - Math.floor(props.settings.delete_warning_after / 60)) * 60
			}
			// @ts-ignore
			else if (value < Math.floor(props.settings.delete_warning_after / 60)) {
				// @ts-ignore
				props.settings.delete_warning_after -= (Math.floor(props.settings.delete_warning_after / 60) - value) * 60
			}

			await onChangeDeleteWarningAfter()
		} else if (value.toString() !== rawValue) {
			if (inputMinRef && inputMinRef.current) inputMinRef.current.value = value.toString()
		}
	}

	async function onChangeDeleteWarningAfterSec(event: FormEvent<HTMLInputElement>) {
		let value: number;
		let rawValue = event.currentTarget.value

		if (rawValue === '') {
			value = 0
		} else {
			value = parseInt(rawValue, 10)
			if (Number.isNaN(value)) {
				value = 0
			}

			if (value > 60) {
				value = 59
			} else if (value < 0) {
				value = 0
			}
		}

		if (value.toString() !== event.currentTarget.value) {
			event.currentTarget.value = value.toString()
		}

		// @ts-ignore
		if (value !== props.settings.delete_warning_after % 60) {
			// @ts-ignore
			if (value > props.settings.delete_warning_after % 60) {
				// @ts-ignoreawait props.updateSettings(true);
				props.settings.delete_warning_after += value - props.settings.delete_warning_after % 60
			} // @ts-ignore
			else if (value < props.settings.delete_warning_after % 60) {
				// @ts-ignore
				props.settings.delete_warning_after -= props.settings.delete_warning_after % 60 - value
			}
			await onChangeDeleteWarningAfter()
		} else if (value.toString() !== rawValue) {
			if (inputSecRef && inputSecRef.current) inputSecRef.current.value = value.toString()
		}
	}

	async function onChangeDeleteWarningAfter() {
		// @ts-ignore
		if (props.settings.delete_warning_after < 0) {
			props.settings.delete_warning_after = 0;
		}
		// @ts-ignore
		else if (props.settings.delete_warning_after > 180) {
			props.settings.delete_warning_after = 180
		}
		await props.updateSettings(true);
	}


	return (
		<div className='punishment-settings-container'>
			<div className='punishment-settings-block'>
                <span>
                    Количество упоминаний
                </span>
				<input defaultValue={props.settings.mentions_threshold} maxLength={2} onChange={onChangeMentionsThreshold}/>
			</div>
			<div className='punishment-settings-block'>
                <span>
                    Количество упоминаний для предупреждения
                </span>
				<input defaultValue={props.settings.mentions_threshold_warning} maxLength={2}
				       onChange={onChangeMentionsWarningThreshold}/>
			</div>
			<div className='punishment-settings-block'>
                <span>
                    Удалять предупреждения после
                </span>
				<p>
					s
				</p>
				{
					// @ts-ignore
					<input defaultValue={props.settings.delete_warning_after % 60}
					       maxLength={2}
					       onChange={onChangeDeleteWarningAfterSec}
					       ref={inputSecRef}
					/>
				}
				<p>
					m
				</p>
				{
					// @ts-ignore
					<input defaultValue={Math.floor(props.settings.delete_warning_after / 60)}
					       maxLength={2}
					       onChange={onChangeDeleteWarningAfterMin}
					       ref={inputMinRef}
					/>
				}

			</div>
		</div>
	);
};

export default PunishmentMentionSpamSettings;
