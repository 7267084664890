import {FC, FormEvent, MouseEvent, RefObject} from "react";
import './style.css'
import {IPunishmentStage} from "../../models/IPunishmentStage";
import {IPermissions} from "../../models/IPermissions";


interface IProps {
    updateSettings: (withState: boolean) => Promise<void>;
    permissions: IPermissions
    permissions_list: IPermissions[]

    // punishment_stage: IPunishmentStage
    // counts: number[]
}


const PermissionsLevelSelector: FC<IProps> = (props) => {
    // async function onRight(event: MouseEvent<HTMLButtonElement>) {
    //     if (props.counts.includes(props.punishment_stage.count + 1)) {
    //         for (let i = props.punishment_stage.count + 1; i <= 20; i++) {
    //             if (props.counts.includes(i)) {
    //                 continue;
    //             }
    //             props.punishment_stage.count = i
    //             break
    //         }
    //     } else {
    //         props.punishment_stage.count += 1
    //     }
    //     console.log(props.punishment_stage.count, props.counts)
    //     await props.updateSettings(true)
    // }

    async function onLeft(event: MouseEvent<HTMLButtonElement>) {
        for (let i = 0; i < props.permissions_list.length; i++) {
            if (props.permissions_list[i].level === props.permissions.level - 1) {
                props.permissions_list[i].level = props.permissions_list[i].level + 1
                props.permissions.level -= 1;
                await props.updateSettings(true)
                return
            }
        }
        if (props.permissions.level !== 0) {
            props.permissions.level = 0;
        await props.updateSettings(true)
        }
    }
    async function onRight(event: MouseEvent<HTMLButtonElement>) {
        for (let i = 0; i < props.permissions_list.length; i++) {
            if (props.permissions_list[i].level === props.permissions.level + 1) {
                props.permissions_list[i].level = props.permissions_list[i].level - 1
                props.permissions.level += 1;
                await props.updateSettings(true)
                return
            }
        }
        let max = props.permissions.level;
        for (let i = 0; i < props.permissions_list.length; i++) {
            if (props.permissions_list[i].level > max) {
                max = props.permissions_list[i].level
            }
        }
        if (props.permissions.level !== max) {
            props.permissions.level = max;
            await props.updateSettings(true)
        }
    }
    return (
        <div className='punishment-stage-counter-selector'>
            <button className='left-button' onMouseUp={onLeft} disabled={props.permissions.level <= 0}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinejoin="round"
                          strokeWidth="48" d="m184 112 144 144-144 144"></path>
                </svg>
            </button>
            <span>{props.permissions.level}</span>
            <button className='right-button' onMouseUp={onRight}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinejoin="round"
                          strokeWidth="48" d="m184 112 144 144-144 144"></path>
                </svg>
            </button>
        </div>
    );
};

export default PermissionsLevelSelector;
