type JsonData = {
  [key: string]: any;
};


interface IViolation {
    name: string;
    description: string;
}


type DictStringString = {
    [key: string]: string;
}


interface IMessageSettings {
  show_text: string;
  log_header: string;
}

interface IViolations {
  show_text: string;
  log_header: string;
}


interface ILocalizations {
  message_settings: IMessageSettings;
  punishments: DictStringString;
  violations: {[key: string]: IViolation};
  channels: DictStringString;
}



export let LOCALIZATIONS: ILocalizations = {} as ILocalizations;

export function loadLocalizations(lang: string) {
    const localizations: unknown = require(`../assets/localizations/${lang}.json`)
    LOCALIZATIONS = localizations as ILocalizations
}


loadLocalizations(localStorage.getItem('lang') || 'ru')
