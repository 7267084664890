import React, { FC, useContext, useEffect, useState} from 'react';
import {  useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import './style.scss'
import Loader from '../../components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import SaveSettingsButton from "../../components/SaveSettingsButton/SaveSettingsButton";

const Dashboard: FC = () => {
    const { store } = useContext(AuthContext)
    const rawGuildId = useParams().guildId;
    const navigate = useNavigate();

    const fetch = async () => {
        await store.checkAuth();

        if (!store.isAuth) {
            store.login(navigate)
            return
        }

        if (!rawGuildId || rawGuildId.match(/^\d{15,20}$/g) === null) {
            store.guilds(navigate)
            return
        }
        const guildId = BigInt(rawGuildId);
        if (!guildId || Number.isNaN(guildId)) {
            store.guilds(navigate)
            return
        }
        await store.getGuild(guildId);

        if (store.guild && store.guild.info && store.guild.info.name) document.title = store.guild.info.name;
    }

    useEffect( ()  => {

        if (localStorage.getItem('token')) fetch();
        else store.login(navigate)
    }, [])


    if (store.isLoading) return <Loader text='Получаю данные'/>

    if (!store.guild) {
        store.guilds(navigate)
    }

    return (
        <>

        </> 
    );
}

export default observer(Dashboard);