import {Component, FC, FormEvent, useState} from "react";
import './style.css'

interface IProps {
    text: string
}


const ErrorServer: FC<IProps> = (props) => {
    return (
        <div className='error-server'>
            <span>{props.text}</span>
        </div>
    );
};

export default ErrorServer;
