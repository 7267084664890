import './style.scss'
import MenuUserButton  from '../MenuUserButton/MenuUserButton'

const Header = () => {
	return (
		<header>
			<MenuUserButton/>
		</header>

	);
}

export default Header;
