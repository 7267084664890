import React, {useContext, FC, MouseEvent, FormEvent, useRef, useEffect} from 'react';
import './style.css'
import { AuthContext } from '../../providers/AuthProvider';
import { ReactComponent as PlusLogo } from '../../assets/plus.svg';
import {IPunishment} from "../../models/IPunishment";
import {IMessageSettings} from "../../models/IMessageSettings";
import {IPunishmentStage} from "../../models/IPunishmentStage";
import Punishment from "../Punishment/Punishment";
import PunishmentStageCounterSelector from "../PunishmentStageCounterSelector/PunishmentStageCounterSelector";
import {IGuildRole} from "../../models/IGuildRole";
import PunishmentEnum from "../../enums/Punishment";
import {Switch} from "antd";
import {IGuild} from "../../models/IGuild";
import {runInAction} from "mobx";
import {log} from "console";
import {observer} from "mobx-react-lite";
import {uuid} from "../../utils/uuidUtils";

interface IProps {
	guild: IGuild,
	violation: string;
	punishment_stage: IPunishmentStage
	punishment_stage_idx: number
	message_settings: IMessageSettings
	updateSettings: (withState: boolean) => Promise<void>;
}

const PunishmentStage: FC<IProps> = (props) => {
	const { store } = useContext(AuthContext)
	// const inputCountRef = useRef<HTMLInputElement>(null)
	//
	//  useEffect(() => {
	//     // Everything around if statement
	//     if (inputCountRef && inputCountRef.current) {
	//         inputCountRef.current.addEventListener('focusout', onChangeCountPunishment)
	//         return () => {
	//             if (inputCountRef && inputCountRef.current) {
	//                 inputCountRef.current.removeEventListener('focusout', onChangeCountPunishment)
	//             }
	//         }
	//     }
	//   }, [inputCountRef])

	function getAvalaiblePunishmentsAndRoles(punishments: IPunishment[]) {
		let availablePunishments: number[] = [1, 2, 3, 4, 5, 6] //.map(
		type roles = {
			[key: string]: IGuildRole
		}
		let availableRoles: roles = {}

		for (let i = 0; i < props.guild.roles.length; i++) {
			if (props.guild.roles[i].id === props.guild.id.toString()) continue
			availableRoles[props.guild.roles[i].id] = props.guild.roles[i]
		}

		for (let i = 0; i < punishments.length; i++) {
			let punishment = punishments[i]
			if (PunishmentEnum.give_role === punishment.punishment ||
				PunishmentEnum.take_role === punishment.punishment
			) {
				if (Object.keys(availableRoles).length === 0) {
					let idx = availablePunishments.indexOf(punishments[i].punishment)
					idx > -1 && availablePunishments.splice(idx, 1)
				} else if (punishment.role_id) {
					delete availableRoles[punishment.role_id]
				}
			} else if (availablePunishments.includes(punishments[i].punishment)) {
				availablePunishments.splice(availablePunishments.indexOf(punishments[i].punishment), 1)
			}
		}
		return {'punishments': availablePunishments,
				'roles': availableRoles}
	}


	async function onPunishmentAdd(event: MouseEvent<HTMLButtonElement>) {
		if (!store.guild) return
		if (props.punishment_stage.punishments.length >= store.guild.limits.max_give_punishments_one_time) {
			// may be reload page?
			return
		}

		let punishments = getAvalaiblePunishmentsAndRoles(props.punishment_stage.punishments)['punishments']
		if (punishments.length === 0) return
		runInAction(() => {
			props.punishment_stage.punishments.push(
				{
					"punishment": punishments[0],
					"duration": 60,
					'id': uuid()
				} as IPunishment
			)
		})

		await props.updateSettings(false);
	}

	async function onChangeDeleteMessage(checked: boolean) {
		props.punishment_stage.delete_message = checked
		await props.updateSettings(true)
	}

	return (
		<div className='punishment-stage-container'>
			<div className='punishment-stage-header'>
				<span>Нарушений</span>
				<PunishmentStageCounterSelector updateSettings={props.updateSettings}
				                                counts={props.message_settings.punishments.map((elem) => elem.count)}
				                                punishment_stage={props.punishment_stage}/>
			</div>
			<hr/>
			{
				props.violation !== 'hacked_account' &&
                <div className='punishment-stage-delete-message-container'>
                    <span>
                        Удалять сообщение
                    </span>
                    <Switch checked={props.punishment_stage.delete_message} onChange={onChangeDeleteMessage}
                            size='20px'/>
                </div>
			}
			<div className='punishment-stage-punishments'>
				{props.punishment_stage.punishments.map((data, i) => {
					return (
						<>
							{i !== 0 && <hr/>}
							<Punishment
								key={data.id}
								guild={props.guild}
								punishment={data}
								punishment_stages={props.message_settings.punishments}
								punishment_idx={i}
								punishment_stage_idx={props.punishment_stage_idx}
								availablePunishmentsAndRoles={getAvalaiblePunishmentsAndRoles(props.punishment_stage.punishments)}
								updateSettings={props.updateSettings}/>
						</>
					)
				})}
				{props.punishment_stage.punishments.length < props.guild.limits.max_give_punishments_one_time &&
					getAvalaiblePunishmentsAndRoles(props.punishment_stage.punishments)['punishments'].length > 0 &&
                    <div className='punishment-block'>
                        <div className='punishment-add'>
                            <button onMouseUp={onPunishmentAdd}>
                                <PlusLogo width='40px' height='40px'/>
                            </button>
                        </div>
                    </div>
				}
			</div>
		</div>
	)
}

export default observer(PunishmentStage);