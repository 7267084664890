import { IUser } from "../models/IUser";
import { makeAutoObservable } from 'mobx'
import AuthService from "../services/AuthService";
import GuildService from "../services/GuildService";
import DiscordService from "../services/DiscordService";
import { loadLocalizations } from "../services/LocalizationsService";
import { IGuild, IGuildIcon } from "../models/IGuild";
import { AxiosError } from "axios";
import guildDto from "../dtos/guildDto";
import React, {Dispatch, SetStateAction} from "react";
import {Navigate } from "react-router-dom";
import {IGuildInfo} from "../models/IGuildInfo";
import {IUserInfo} from "../models/IUserInfo";
import { DEPLOY_MODE } from "../config";
import localStorageSet from "../utils/localStorageUtils";
import {uuid} from "../utils/uuidUtils";

// const {, computed, action} = mobx;

function ensureError(value: unknown): Error {
    if (value instanceof Error) return value

    let stringified = '[Unable to stringify the thrown value]'
    try {
        stringified = JSON.stringify(value)
    } catch {}

    return new Error(`This value was thrown as is, not through an Error: ${stringified}`)
}


export default class Store {
    user: IUser | null = {} as IUser;
    guild: IGuild | null = {} as IGuild;

    user_guilds: IGuildIcon[] | null = null

    guildList: IGuildInfo[] = [];
    userList: IUserInfo[] = [];

    changed: boolean = false;
    
    error: Error | undefined = undefined

    isAuth: boolean = false;
    isLoading: boolean = false;
    loadingTime: number | null = 0;

    setDisabledSettings: Dispatch<SetStateAction<boolean>> | null = null;


    checkLoadingTime = () => {
        return this.loadingTime !== null && Date.now() - this.loadingTime > 150;

    }

    constructor() {
        makeAutoObservable(this);

        const guilds = localStorage.getItem('guilds')
        if (guilds) {
            this.guildList = JSON.parse(guilds);
        } else {
            this.guildList = []
        }

        const users = localStorage.getItem('users')
        if (users) {
            this.userList = JSON.parse(users);
        } else {
            this.userList = []
        }
    }

    setChanged = (value: boolean) => {
        this.changed = value;
    }

    setGuildList() {
        localStorageSet('guilds', JSON.stringify(this.guildList))
    }

    setUserList() {
        localStorageSet('users', JSON.stringify(this.userList))
    }


    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    async setUser(user: IUser) {
        this.user = user;
        if (user.lang) {
            if (user.lang !== localStorage.getItem('lang')) {
                loadLocalizations(user.lang);
            }
            localStorageSet('lang', user.lang)
        }
    }

    setGuild(guild: IGuild | null) {
        this.guild = guild;
    }

    setLoading(bool: boolean) {
        if (bool) {
            this.loadingTime = Date.now();
        } else {
            this.loadingTime = null; 
        }
        if (bool === this.isLoading) return
        this.isLoading = bool;
    }

    guilds(navigate: any) {
        navigate('/guilds')
    }

    home(navigate: any) {
        navigate('/')
    }

    base_guild_dashboard(location: any,
                         children: any) {
        const parts = location.pathname.split('/')
        let flag = false;
        let guild_id = ''

        for (let i = 0; i < parts.length; i++) {
            if (parts[i] === 'guilds') {
                flag = true
                continue
            }
            if (flag) {
                guild_id = parts[i]
                break
            }
        }
        // @ts-ignore
        return (
            // @ts-ignore
            <Navigate to={'/guilds/' + guild_id} replace={true}>
                {children}
            </Navigate>)
    }

    login(navigate: any) {
        if (DEPLOY_MODE)
            window.location.href = 'https://discord.com/oauth2/authorize?client_id=1219270781851402350&response_type=code&redirect_uri=https%3A%2F%2Fapi.botx.site%2Fauth%2Flogin%2Fcallback%2F&scope=identify+guilds'
        else
            window.location.href = 'https://discord.com/oauth2/authorize?client_id=1219270781851402350&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fauth%2Flogin%2Fcallback%2F&scope=identify+guilds'
    }


    validateData(): boolean {
        return !this.guild ||
            !this.isAuth ||
            !this.user ||
            !this.guild.permissions ||
            !this.guild.main_settings ||
            !this.guild.message_settings ||
            !this.guild.limits ||
            !this.guild.verification_settings ||
            !this.guild.info
    }

    async handleError(error: AxiosError) {
        const response = error.response
        if (error.response === undefined) return
        if (response?.status === 401) {
            localStorage.clear()
            return
        }
        this.error = error
    }

    async logout(navigate: any) {
        localStorage.clear();
        this.setAuth(false)
        this.setLoading(true)
        this.setGuild({} as IGuild)
        await this.setUser({} as IUser)
        
        try {
            await AuthService.logout();
        } catch (e: unknown) {
        }
        this.setLoading(false)
        navigate('/')
    }

    async checkAuth() {
        this.setLoading(true)
        this.setAuth(false)
        this.setGuild({} as IGuild)
        await this.setUser({} as IUser)

        try {
            const response = await AuthService.refresh();

            this.setLoading(false);
            if (!response.data || !response.data.accessToken || !response.data.user || !response.data.refreshToken) {
                localStorage.clear()
                return
            }
            try {
                localStorage.setItem('token', response.data.accessToken);
            } catch (e) {
                if (!DEPLOY_MODE) {
                    console.log('Не удалось записать в local storage')
                }
                localStorage.clear()
                this.setAuth(false);
                return;
            }

            this.setAuth(true);

            await this.setUser(response.data.user);
        } catch (err) {
            if (err instanceof AxiosError) {
                await this.handleError(err)
            }
            localStorage.clear()
            const error = ensureError(err)
            this.setAuth(false);
            this.setLoading(false);
        }
    }

    async getGuilds(): Promise<void>   {
        this.setLoading(true);

        try {
            const response = await GuildService.getGuilds();
            this.setLoading(false);
            if (response?.data) {
                this.user_guilds = response.data
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                await this.handleError(err)
            }
            if (!DEPLOY_MODE) {
                const error = ensureError(err)
                console.log(error)
                console.log(error.message)
            }
            this.setLoading(false);
        }
    }

    async getGuild(guildId: BigInt) {
        this.setLoading(true);
        this.setGuild(null);
        try {
            const guild = (await GuildService.getGuild(guildId)).data;
            for (const [key, value] of Object.entries(guild.message_settings)) {
                for (let i = 0; i < value.punishments.length; i++) {
                    value.punishments[i].id = uuid()
                    for (let j = 0; j < value.punishments[i].punishments.length; j++) {
                        value.punishments[i].punishments[j].id = uuid()
                    }
                }
            }

            let unknownUsers: string[] = []
            let unknownGuilds: string[] = []

            let temp_whitelist = guild.message_settings['discord_invite'].whitelist
            if (temp_whitelist) {
                for (let i = 0; i < temp_whitelist.length; i++) {
                    let flag = false;
                    for (let j = 0; j < this.guildList.length; j++) {
                        if (this.guildList[j].id === temp_whitelist[i]) {
                            flag = true;
                            break
                        }
                    }
                    if (!flag) unknownGuilds.push(temp_whitelist[i])
                }
            }

            for (let i = 0; i < guild.permissions.length; i++) {
                guild.permissions[i].id = uuid()
                for (let j = 0; j < guild.permissions[i].immune_rules.length; j++) {
                    guild.permissions[i].immune_rules[j].id = uuid()
                }

                // проходимся по каждому челику
                for (let x = 0; x < guild.permissions[i].users.length; x++) {
                    let flag = false;
                    for (let y = 0; y < this.userList.length; y++ ) {
                        if (this.userList[y].id === guild.permissions[i].users[x]) {
                            flag = true; // Значит нашли
                            break
                        }
                    }
                    // если не нашли его в списке
                    if (!flag) {
                        unknownUsers.push(guild.permissions[i].users[x]);
                    }
                }
            }


            guild.roleId2role = {}
            for (let i = 0; i < guild.roles.length; i++) {
                guild.roleId2role[guild.roles[i].id] = guild.roles[i]
            }

            guild.channelId2channel = {}
            for (let i = 0; i < guild.channels.length; i++) {
                guild.channelId2channel[guild.channels[i].id] = guild.channels[i]
            }


            for (let i = 0; i < guild.info.access_users.length; i++) {
                let flag = false;
                for (let j = 0; j < this.userList.length; j++ ) {
                    if (this.userList[j].id === guild.info.access_users[i]) {
                        flag = true;
                        break
                    }
                }
                if (!flag) {
                    unknownUsers.push(guild.info.access_users[i]);
                }
            }

            if (unknownUsers.length > 0) {
                const newUsers = (await DiscordService.fetchUsers(unknownUsers)).data
                this.userList = this.userList.concat(newUsers)
                this.setUserList();
            }

            if (unknownGuilds.length > 0) {
                const newGuilds = (await DiscordService.fetchGuilds(unknownGuilds)).data
                this.guildList = this.guildList.concat(newGuilds)
                this.setGuildList();
            }

            this.setLoading(false);

            this.setGuild(guild)
        } catch (err) {
            if (err instanceof AxiosError) {
                await this.handleError(err)
            }
            const error = ensureError(err)
            if (!DEPLOY_MODE) {
                console.log(error)
                console.log(error.message)
            }
            this.setGuild(null);
        }
        this.setLoading(false);
    }
    async updateGuild() {
        if (!this.guild || !this.user) {
            return
        }

        this.setLoading(true);
        
        try {
            await GuildService.updateGuild(new guildDto(this.guild));
            window.location.reload();
        } catch (err) {
            if (err instanceof AxiosError) {
                await this.handleError(err)
            }
            const error = ensureError(err)
            if (!DEPLOY_MODE) {
                console.log(error)
                console.log(error.message)
            }
        }
        this.setLoading(false);
    }

    async fetchDiscordGuild(guildId: string | BigInt) {
        for (let i = 0; i < this.guildList.length; i++) {
            if (this.guildList[i].id === guildId) {
                return this.guildList[i]
            }
        }
        this.setLoading(true);

        try {
            const response = await DiscordService.fetchGuild(guildId);
            this.setLoading(false);

            let flag = false
            for (let i = 0; i < this.guildList.length; i++) {
                if (this.guildList[i].id === response.data.id) {
                    flag = true;
                    break
                }
            }
            if (!flag) {
                if (response.data?.code) {
                    if (response.data?.code === 401) {
                        this.guildList.push({'id': response.data.id,
                                             'icon': null,
                                             'name': null} as IGuildInfo)
                    }
                } else {
                    this.guildList.push(response.data)
                }
            }
            this.setGuildList()
            return response.data
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 404) {
                    this.setLoading(false)
                    return null
                }
                await this.handleError(err)
            }

            const error = ensureError(err)

            if (!DEPLOY_MODE) {
                console.log(error)
                console.log(error.message)
            }
            this.setLoading(false);
            return null
        }
    }

    async fetchDiscordUser(userId: string | BigInt) {
        this.setLoading(true);

        try {
            const response = await DiscordService.fetchUser(userId);
            this.setLoading(false);

            let flag = false
            for (let i = 0; i < this.userList.length; i++) {
                if (this.userList[i].id === response.data.id) {
                    flag = true;
                    break
                }
            }
            if (!flag) this.userList.push(response.data)
            this.setUserList()


            return response.data
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 404) {
                    this.setLoading(false)
                    return null
                }
                await this.handleError(err)
            }

            const error = ensureError(err)

            if (!DEPLOY_MODE) {
                console.log(error)
                console.log(error.message)
            }

            this.setLoading(false);
            return null
        }
    }
}