import React, { FC, ReactNode } from "react";
import './style.css'
import Store from "../../store/store";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";
import DiscordElementList from "../DiscordElementList/DiscordElementList";

interface IProps {
	store: Store
	placeholder: string
	updateSettings: (withState: boolean) => Promise<void>;
	list: string[]
	limit: number
	isOwner: boolean
	padding: boolean
}


const TextList: FC<IProps> = (props)    => {
	async function onAdd(value: string, ref: HTMLInputElement) {
		if (value.length === 0 || value.length > 260) return

		if (props.list.includes(value)) {
			return
		}
		props.list.push(value)
		await props.updateSettings(true)
	}

	async function onDelete(value: string) {
		const index = props.list.indexOf(value)
		console.log(index)
		if (index === -1) return

		runInAction(() => {
			props.list.splice(index, 1)
		})

		console.log(props.store.changed)
		await props.updateSettings(true);
		console.log(props.store.changed)
	}

	function getElement(value: any): ReactNode {
		return <DiscordObjectElement
			key={value}
			store={value.store}
			onDelete={value.onDelete}
			value={value.value}
			icon={undefined}
			text={value.value}
		/>
	}

	return (
		<DiscordElementList store={props.store}
		                    updateSettings={props.updateSettings}
		                    onAdd={onAdd}
		                    onDelete={onDelete}
		                    getElement={getElement}
		                    list={props.list}
		                    limit={props.limit}
		                    placeholder={props.placeholder}
		                    isOwner={props.isOwner}
		                    padding={props.padding}
		/>
	)
}

export default observer(TextList);
