import React, { FC } from "react";
import './style.css'
import Store from "../../store/store";
import DiscordObjectElement from "../DiscordObjectElement/DiscordObjectElement";

interface IProps {
	store: Store
	guildId: string
	onDelete: (userId: any) => Promise<void>;
}


const GuildElement: FC<IProps> = (props) => {
	let text = props.guildId;
	let icon: string | null = null;

	for (let i = 0; i < props.store.guildList.length; i++) {
		if (props.store.guildList[i].id === props.guildId) {
			text = props.store.guildList[i].name || props.guildId;
			if (props.store.guildList[i].icon) {
				icon = `https://cdn.discordapp.com/icons/${props.guildId}/${props.store.guildList[i].icon}.png?size=48`
			}
			break
		}
	}
	return <DiscordObjectElement store={props.store}
							     onDelete={props.onDelete}
	                             value={props.guildId}
	                             icon={icon}
	                             text={text}/>
};

export default GuildElement;
