import React, {FC, useContext, useState, MouseEvent, FormEvent, useEffect, useRef} from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import './style.css'
import Loader from '../../components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import ErrorServer from "../../components/ErrorServer/ErrorServer";
import {useLocation} from "react-router-dom";
import {IGuild} from "../../models/IGuild";
import {IUser} from "../../models/IUser";
import SaveSettingsButton from "../../components/SaveSettingsButton/SaveSettingsButton";
import UserList from "../../components/UserList/UsersList";
import {AUTHOR_DID} from "../../config";

const DashboardMain: FC = () => {
    const { store } = useContext(AuthContext)

    const [disabled, setDisabled] = useState<boolean>(false);
    const [updateState, setUpdateState] = useState<boolean>(false);
    const location = useLocation()

    const refUserAccessInput = useRef<HTMLInputElement>(null)

    store.setDisabledSettings = null;

    useEffect(() => {
    }, []);

    if (store.isLoading) {
        return <Loader text='Получаю данные'/>
    }

    if (store.validateData() || !store.guild || !store.user) {
        return store.base_guild_dashboard(location, <ErrorServer text='Не удалось получить данные с сервера'/>)
    }

    const guild: IGuild = store.guild
    const user: IUser = store.user

    const isOwner = guild.owner_id === user.id || user.id === AUTHOR_DID

    async function updateSettings(withState: boolean | void = true): Promise<void> {
        if (store.changed) return
        withState && setUpdateState((prev) => !prev);
        store.setChanged(true);
    }


    return (
        <section className='settings-section'>
            <div className='settings-header'>
                <div className='settings-header-title'>
                    <h2>
                        Основные настройки
                    </h2>
                </div>
            </div>
            <div className='settings-block-header' style={{marginTop: '15px'}}>
                <span>
                    Доступ к настройкам
                </span>
            </div>
            <div className='access-users-contaiwner'>
                {
                    !isOwner && <div className='user-is-not-owner'>
                        <span>
                            Вы не владелец
                        </span>
                    </div>
                }
                <div className='user-list' style={isOwner ? {} :
                    {'pointerEvents': 'none', 'filter': 'blur(10px)'}}>
                    <UserList store={store}
                              updateSettings={updateSettings}
                              list={guild.info.access_users}
                              limit={guild.limits.max_access_users}
                              isOwner={isOwner}
                              padding={true}/>
                </div>
            </div>
            {store.changed && <SaveSettingsButton setDisabled={setDisabled}/>}
        </section>)
};

export default observer(DashboardMain);