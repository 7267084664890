import $api from "../http";
import { AxiosResponse } from 'axios';
import {IGuildInfo} from "../models/IGuildInfo";
import {IUserInfo} from "../models/IUserInfo";


export default class GuildService {
    static async fetchUser(userId: string | BigInt): Promise<AxiosResponse<IUserInfo>> {
        return $api.get<IUserInfo>(`/discord/user/${userId}`);
    }

    static async fetchUsers(users: string[]): Promise<AxiosResponse<IUserInfo[]>> {
        return $api.post<IUserInfo[]>(`/discord/users/`, {users});
    }

    static async fetchGuild(guildId: string | BigInt): Promise<AxiosResponse<IGuildInfo>> {
        return $api.get<IGuildInfo>(`/discord/guild/${guildId}`);
    }

    static async fetchGuilds(guilds: string[]): Promise<AxiosResponse<IGuildInfo[]>> {
        return $api.post<IGuildInfo[]>(`/discord/guilds/`, {guilds});
    }
}