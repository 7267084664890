import './style.css'

import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom'
import {
	MessageOutlined,
	LinkOutlined,
	SettingOutlined,
	FileOutlined,
	UserOutlined,
	FileWordOutlined, UsergroupAddOutlined, LockOutlined, HistoryOutlined, BarChartOutlined, CheckSquareOutlined,
} from '@ant-design/icons';
import './style.css'
import { Menu } from 'antd';
import {FC} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {observer} from "mobx-react-lite";
import {LOCALIZATIONS} from "../../services/LocalizationsService";

function getViolationNavLink(name: string) {
	return <NavLink to={name}>
		{LOCALIZATIONS.violations[name].name}
	</NavLink>
}

function getItems() {
	return [{
				key: 'main',
				icon: <LinkOutlined />,
				label: (
					<NavLink to='main'>
						Основные
					</NavLink>
				),
			},
			{
				key: 'stats',
				icon: <BarChartOutlined />,
				label: (
					<NavLink to='stats'>
						Статистика
					</NavLink>
				),
			},
			{
				key: 'message',
				label: 'Модерация чатов',
				icon: <MessageOutlined />,
				children: [
					{ key: 'master', icon: <SettingOutlined />,
						label: getViolationNavLink('master')},
							// (
							// <NavLink to='master'>
							// 	Мастер настройка
							// </NavLink>) },
					{ key: 'file', icon: <FileOutlined />, label: (
							<NavLink to='file'>
								Вредоносные файлы
							</NavLink>) },
					{ key: 'hacked_account', icon: <UserOutlined />, label: (
							<NavLink to='hacked_account'>
								Взломанный аккаунт
							</NavLink>) },
					{ key: 'spam_nn', icon: <MessageOutlined/>, label: (
							<NavLink to='spam_nn'>
								Реклама/Спам
							</NavLink>) },
					{ key: 'spam', icon: <MessageOutlined />, label: (
							<NavLink to='spam'>
								Спам
							</NavLink>) },
					{ key: 'flood', icon: <MessageOutlined />, label: (
							<NavLink to='flood'>
								Флуд
							</NavLink>) },
					{ key: 'hyperlink', icon: <LinkOutlined />, label: (
							<NavLink to='hyperlink'>
								Ссылка в гиперссылке
							</NavLink>) },
					{ key: 'discord_invite', icon: <LinkOutlined />, label: (
							<NavLink to='discord_invite'>
								Discord приглашение
							</NavLink>) },
					{ key: 'scam_url', icon: <LinkOutlined />, label: (
							<NavLink to='scam_url'>
								Вредоносные сайты
							</NavLink>) },
					{ key: 'pirate_url', icon: <LinkOutlined />, label: (
							<NavLink to='pirate_url'>
								Пиратские сайты
							</NavLink>) },
					{ key: 'adult_url', icon: <LinkOutlined />, label: (
							<NavLink to='adult_url'>
								Сайты для взрослых
							</NavLink>) },
					{ key: 'casino_url', icon: <LinkOutlined />, label: (
							<NavLink to='casino_url'>
								Казино сайты
							</NavLink>) },
					{ key: 'http_url', icon: <LinkOutlined />, label: (
							<NavLink to='http_url'>
								HTTP ссылки
							</NavLink>) },
					{ key: 'blacklist_word', icon: <FileWordOutlined />, label: (
							<NavLink to='blacklist_word'>
								Черный список слов
							</NavLink>) },
					{ key: 'blacklist_url', icon: <LinkOutlined />, label: (
							<NavLink to='blacklist_url'>
								Черный список сайтов
							</NavLink>) },
					{ key: 'blacklist_file', icon: <FileOutlined />, label: (
							<NavLink to='blacklist_file'>
								Черный список файлов
							</NavLink>) },
					{ key: 'mention_spam', icon: <UsergroupAddOutlined />, label: (
							<NavLink to='mention_spam'>
								Спам упоминаниями
							</NavLink>) },
				],
			},
			{
				key: 'permissions',
				icon: <LockOutlined />,
				label: (
					<NavLink to='permissions'>
						Права
					</NavLink>
				),
			},
			{
				key: 'logs',
				icon: <HistoryOutlined />,
				label: (
					<NavLink to='logs'>
						Логирование
					</NavLink>
				),
			},
			{
				key: 'backups',
				icon: <CheckSquareOutlined />,
				label: (
					<NavLink to='backups'>
						Бэкапы
					</NavLink>
				),
			},
			{
				key: 'guilds',
				icon: <UsergroupAddOutlined />,
				label: (
					<NavLink to='/guilds'>
						Список серверов
					</NavLink>
				),
			},
		];
}

const Sidebar: FC = () => {
	const { store } = useContext(AuthContext)
	let icon_url = null;

	if (store.guild && store.guild.info && store.guild.info.icon) {
		icon_url = `https://cdn.discordapp.com/icons/${store.guild.id}/${store.guild.info.icon}.png?size=128`
	}
	return (
		<aside>
			{/*{store.guild?.info &&*/}
			{/*	<div className='guild-banner'>*/}
			{/*		{icon_url ? <img className='guild-img' src={icon_url}*/}
			{/*		                 alt='discord server logo'/>*/}
			{/*			:*/}
			{/*			<QuestionLogo width='128px' height='128px'/>*/}
			{/*		}*/}
			{/*		<div className='guild-name'>*/}
			{/*			<p>*/}
			{/*				{store.guild.info.name}*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*	</div>}*/}


			{
				// @ts-ignore
				<Menu
				style={{width: 350}}
				defaultSelectedKeys={[]}
				defaultOpenKeys={[]}
				mode='inline'// @ts-ignore
				items={getItems()} // @ts-ignore
			/>
			}
		</aside>
	)

	// return (
	// 	<aside>
	// 		<IconContext.Provider value={{ color: '#fff' }}>
	// 			<div className='sidebar-container'>
	// 				<div className='sidebar-wrap'>
	// 					{getData().map((item) => {
	// 						return <SubMenu props={item} key={item.title} />;
	// 					})}
	// 				</div>
	// 			</div>
	// 		</IconContext.Provider>
	// 	</aside>
	// );
};

export default observer(Sidebar);

