import { Routes, Route,  BrowserRouter } from "react-router-dom";
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardPermission from "./pages/DashboardPermission/DashboardPermission";
import SelectGuild from './pages/SelectGuild/SelectGuild'
import DashboardOutlet from './pages/DashboardOutlet/DashboardOutlet'
import Login from './pages/Login/Login';
import Violations from "./models/Violations";
import './index.scss'

import DashboardMessage from "./pages/DashboardMessage/DashboardMessage";
import DashboardMain from "./pages/DashboardMain/DashboardMain";
import DashboardLogs from "./pages/DashboardLogs/DashboardLogs";
import DashboardStatistics from "./pages/DashboardStatistics/DashboardStatistics";
import NotFound from "./pages/NotFound/NotFound";
import DashboardBackUps from "./pages/DashboardBackUps/DashboardBackUps";



const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path='login' element={<Login/>} />
                <Route path="/guilds" element={<SelectGuild/>}/>
                <Route path="/guilds/:guildId/" element={<DashboardOutlet/>}>
                    <Route index element={<Dashboard/>}/>
                    <Route path='master' element={<DashboardMessage name='master' />}/>
                    {Object.keys(Violations).map((elem) => {
                        return <Route path={elem} element={<DashboardMessage name={elem} key={elem}/>} />
                    })}
                    <Route path="main" element={<DashboardMain />} />
                    <Route path="stats" element={<DashboardStatistics />} />
                    <Route path="permissions" element={<DashboardPermission />} />
                    <Route path="logs" element={<DashboardLogs />} />
                    <Route path="backups" element={<DashboardBackUps />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
