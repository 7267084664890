import { FC, useEffect, useContext } from 'react';
import Header from '../../components/Header/Header';
import { AuthContext } from '../../providers/AuthProvider';
import {observer} from "mobx-react-lite";
import './style.css'

const Home: FC = () => {
	const { store } = useContext(AuthContext)

	useEffect( () => {
		store.checkAuth();
	}, []);

	return (
		<>
			<Header/>
			<main>
				<div className='title-container'>
					<h2 className='text-container'>
						Модерация и безопасность
					</h2>
					<h2 className='text-container'>
						ваших серверов
					</h2>
				</div>
				<div className='opportunities-container'>
					<span className='text-container'>
						Наши возможности
					</span>
				</div>
			</main>
		</>

	);
};

export default observer(Home);