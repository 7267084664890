import { FC, FormEvent } from "react";
import './style.css'
import {ISendSettings} from "../../models/ISendSettings";

interface IProps {
    type: string
    name: string
    time: number
    updateSettings: (withState: boolean) => Promise<void>;
    settings: ISendSettings
}


const SendSettingsDeleteAfter: FC<IProps> = (props) => {
    let limit: number = 0;
    if (props.type === 'm')  {
        limit = 5
    } else {
        limit = 60;
    }

    async function onChange(event: FormEvent<HTMLInputElement>) {
        let value: number;
        if (event.currentTarget.value === '') {
            value = 0
        } else {
            value = parseInt(event.currentTarget.value, 10)
            if (Number.isNaN(value)) value = 0;
            if (value >= limit) {
                value = limit - 1
            }
        }

        if (value.toString() !== event.currentTarget.value) {
            event.currentTarget.value = value.toString()
        }
        let old_value, new_value;
        switch (props.type) {
            case 'm':
                old_value = Math.floor(props.settings.delete_after / 60)
                new_value = props.settings.delete_after - old_value + Math.floor(value * 60)
                break
            case 's':
                old_value = Math.floor(props.settings.delete_after % 60)
                new_value = props.settings.delete_after - old_value + value
                break
            default:
                return;
        }
        if (new_value < 0) {
            props.settings.delete_after = 0;
            await props.updateSettings(true);
        } else if (new_value > 3 * 60) {
            props.settings.delete_after = 3 * 60
            await props.updateSettings(true);
        } else if (value !== old_value) {
            props.settings.delete_after = new_value
            await props.updateSettings(true);
        }
    }
    return (
        <div className='send-settings-delete-after'>
            <input defaultValue={props.time} maxLength={2} onChange={onChange}/>
            <span>{props.name}</span>
        </div>
    );
};

export default SendSettingsDeleteAfter;
