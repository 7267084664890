import { useContext, FC } from 'react';
import './style.css'
import { AuthContext } from '../../providers/AuthProvider';


interface IProps {
    setDisabled: any
}

const SaveSettingsButton: FC<IProps> = (props) => {
    const { store } = useContext(AuthContext)

    async function callback() {
        if (store.isLoading) return
        props.setDisabled(true)
        await store.updateGuild()
        store.setChanged(false);
        props.setDisabled(false)
    }

    return (
        <button className="save-settings-container" disabled={store.isLoading} onMouseUp={callback}>
            <div className="save-settings-text">
                Сохранить
          </div>
      </button>
    );
}

export default SaveSettingsButton;