import {Component, FC, FormEvent, MouseEvent, useState} from "react";
import './style.css'

interface IProps {
    onRight: (event: MouseEvent<HTMLButtonElement>) => Promise<void>
    onLeft: (event: MouseEvent<HTMLButtonElement>) => Promise<void>
    text: string
}


const PunishmentSelector: FC<IProps> = (props) => {
    return (
        <div className='punishment-selector'>
            <button className='left-button' onMouseUp={props.onLeft}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinejoin="round"
                          strokeWidth="48" d="M328 112 184 256l144 144"></path>
                </svg>
            </button>
            <span>
                {props.text}
            </span>
            <button className='right-button' onMouseUp={props.onRight}>
                <svg fill="currentColor" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinejoin="round"
                          strokeWidth="48" d="m184 112 144 144-144 144"></path>
                </svg>
            </button>
        </div>
    );
};

export default PunishmentSelector;
