import $api from "../http";
import {IRefreshData} from "../models/IRefreshData";
import { AxiosResponse } from 'axios';


export default class AuthService {
    static async logout(): Promise<AxiosResponse> {
        return await $api.post('auth/logout/');
    }

    static async refresh(): Promise<AxiosResponse<IRefreshData>> {
        return await $api.get('auth/refresh/');
    }

}