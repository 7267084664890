import $api from "../http";
import { AxiosResponse } from 'axios';
import { IGuild, IGuildIcon } from "../models/IGuild";
import guildDto from "../dtos/guildDto";

export default class GuildService {
    static async getGuilds(): Promise<AxiosResponse<Array<IGuildIcon>>> {
        return $api.get<Array<IGuildIcon>>('/guilds/');
    }

    static async getGuild(guildId: BigInt): Promise<AxiosResponse<IGuild>> {
        return $api.get<IGuild>(`/guilds/${guildId}/`);
    }

    static async updateGuild(guild: guildDto): Promise<AxiosResponse> {
        return $api.put<guildDto>(`/guilds/`, guild);
    }
}