import React, {FC, useContext, useEffect} from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import {AuthContext} from "../../providers/AuthProvider";

const DashboardOutlet: FC = () => {
    return (
        <>
            <Sidebar/>
            <Outlet/>
        </>
    );
}

export default DashboardOutlet;