import { FC, FormEvent } from "react";
import './style.css'
import {IPunishment} from "../../models/IPunishment";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx"
import PunishmentEnum from "../../enums/Punishment";

interface IProps {
    type: string
    name: string
    time: number
    updateSettings: (withState: boolean) => Promise<void>;
    punishment: IPunishment
}


const PunishmentDuration: FC<IProps> = (props) => {
    let limit: number = 0;
    if (props.type === 'd')  {
         limit = 31
    }  else if (props.type === 'h')  {
        limit = 24
    } else {
        limit = 60;
    }

    async function onChange(event: FormEvent<HTMLInputElement>) {
        let value: number;
        if (event.currentTarget.value === '') {
            value = 0
        } else {
            value = parseInt(event.currentTarget.value, 10)
            if (value >= limit) {
                value = limit - 1
            }
        }

        let old_value = 0, new_value = 0;
        switch (props.type) {
            case 'd':
                old_value = Math.floor(props.punishment.duration / 3600 / 24)
                if (old_value > value) {
                    new_value = Math.floor((old_value - value) * 24 * 3600)
                    new_value = props.punishment.duration - new_value
                } else if (value > old_value) {
                    new_value = Math.floor((value - old_value) * 24 * 3600)
                    new_value = props.punishment.duration + new_value
                }
                break
            case 'h':
                old_value = Math.floor(props.punishment.duration % 86400 / 3600)
                if (old_value > value) {
                    new_value = Math.floor((old_value - value) * 3600)
                    new_value = props.punishment.duration - new_value
                } else if (value > old_value) {
                    new_value = Math.floor((value - old_value) * 3600)
                    new_value = props.punishment.duration + new_value
                }
                break
            case 'm':
                old_value = Math.floor(props.punishment.duration % 86400 % 3600 / 60)
                if (old_value > value) {
                    new_value = Math.floor((old_value - value) * 60)
                    new_value = props.punishment.duration - new_value
                } else if (value > old_value) {
                    new_value = Math.floor((value - old_value) * 60)
                    new_value = props.punishment.duration + new_value
                }

                break
            case 's':
                old_value = Math.floor(props.punishment.duration % 86400 % 3600 % 60)
                if (old_value > value) {
                    new_value = old_value - value
                    new_value = props.punishment.duration - new_value
                } else if (value > old_value) {
                    new_value = value - old_value
                    new_value = props.punishment.duration + new_value
                }
                break
            default:
                return;
        }
        if (value.toString() !== event.currentTarget.value) {
            event.currentTarget.value = value.toString()
        }
        if (new_value < 1 && (props.punishment.punishment === PunishmentEnum.ban || props.punishment.punishment === PunishmentEnum.kick)) {
            runInAction(() => {
                props.punishment.duration = 0
            })
            await props.updateSettings(true);
        }
        else if (new_value < 10) {
            runInAction(() => {
                props.punishment.duration = 10
            })
            await props.updateSettings(true);
        } else if (new_value > 31 * 24 * 3600) {
            runInAction(() => {
                props.punishment.duration = 31 * 24 * 3600
            })
            await props.updateSettings(true);
        } else if (value !== old_value) {
            runInAction(() => {
                props.punishment.duration = new_value
            })
            // props.punishment.duration = new_value
            await props.updateSettings(true);
        }
    }

    return (
        <div className='punishment-duration'>
            <input defaultValue={props.time} maxLength={2} onChange={onChange}/>
            <span>{props.name}</span>
        </div>
    );
};

export default observer(PunishmentDuration);
