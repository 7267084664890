import {FC, FormEvent, MouseEvent, RefObject} from "react";
import './style.css'
import {IPunishmentStage} from "../../models/IPunishmentStage";
import {ISendSettings} from "../../models/ISendSettings";


interface IProps {
    updateSettings: (withState: boolean) => Promise<void>;
    send_settings: ISendSettings
}


const SendSettingsForgotText: FC<IProps> = (props) => {
    async function onChange(event: FormEvent<HTMLInputElement>) {
        let value: number;
        if (event.currentTarget.value === '') {
            value = 0
        } else {
            value = parseInt(event.currentTarget.value, 10)
            if (value > 100) {
                value = 100
            } else if (value < 0){
                value = 0
            }
        }

        if (value.toString() !== event.currentTarget.value) {
            event.currentTarget.value = value.toString()
        }

        if (value !== props.send_settings.forgot_text) {
            await props.updateSettings(true);
            props.send_settings.forgot_text = value
        }

    }

    return (
        <div className='send-settings-forgot-text'>
            <input defaultValue={props.send_settings.forgot_text} maxLength={3} onChange={onChange}/>
            <span>%</span>
        </div>
)
    ;
};

export default SendSettingsForgotText;
