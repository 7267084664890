import React, { FC, MouseEvent, SyntheticEvent} from 'react';
import './style.css'
import {LOCALIZATIONS} from "../../services/LocalizationsService";
import { ReactComponent as CrossMarkLogo } from '../../assets/cross-mark.svg';
import {IPunishment} from "../../models/IPunishment";
import {IPunishmentStage} from "../../models/IPunishmentStage";
import PunishmentSelector from "../PunishmentSelector/PunishmentSelector";
import PunishmentDuration from "../PunishmentDuration/PunishmentDuration";
import {IGuildRole} from "../../models/IGuildRole";
import PunishmentEnum from "../../enums/Punishment";
import {IGuild} from "../../models/IGuild";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";

type Role2Role = {
    [key: string]: IGuildRole
}

interface PunishmentsRoles {
    punishments: number[]
    roles: Role2Role;
}


interface IProps {
    guild: IGuild
    punishment: IPunishment;
    punishment_stages: IPunishmentStage[];
    punishment_idx: number;
    punishment_stage_idx: number;
    availablePunishmentsAndRoles: PunishmentsRoles
    updateSettings: (withState: boolean) => Promise<void>;
}

const Punishment: FC<IProps> = (props) => {
    const punishment = props.punishment
    if (!punishment.role_id && (punishment.punishment === PunishmentEnum.take_role || punishment.punishment === PunishmentEnum.give_role)) {
        if (props.guild.roles.length !== 0 ) {
            runInAction(() => {
                punishment.role_id = props.guild.roles[0].id
            })
        }
    }

    async function onPunishmentDelete(event: MouseEvent<HTMLButtonElement>) {
        runInAction(() => {
            props.punishment_stages[props.punishment_stage_idx].punishments.splice(props.punishment_idx, 1)
        })

        if (props.punishment_stages[props.punishment_stage_idx].punishments.length === 0) {
            runInAction(() => {
                props.punishment_stages.splice(props.punishment_stage_idx, 1)
            })
        }
        await props.updateSettings(true)
    }


    async function onRightPunishmentSelector(event: MouseEvent<HTMLButtonElement>) {
        const availablePunishments = props.availablePunishmentsAndRoles.punishments
        if (availablePunishments.length === 0) return

        let punishment_ = punishment.punishment;

        for (let j = 1; j < 7; j++) {
            let flag = false;

            punishment_++;
            for (let i = 0; i < availablePunishments.length; i++) {
                if (availablePunishments[i] === punishment_) {
                    flag = true;
                    break
                }
            }

            if (punishment_ > 6) {
                punishment_ = 1
            }
            if (flag) break
        }

        if (punishment_ !== punishment.punishment) {
            runInAction(() => {
                punishment.punishment = punishment_
            })
            await props.updateSettings(true);
        }
    }

    async function onLeftPunishmentSelector(event: MouseEvent<HTMLButtonElement>) {
        const availablePunishments = props.availablePunishmentsAndRoles.punishments
        if (availablePunishments.length === 0) return

        let punishment_ = punishment.punishment;

        for (let j = 6; j > 0; j--) {
            let flag = false;

            punishment_--;
            for (let i = availablePunishments.length; i > -1; i--) {
                if (availablePunishments[i] === punishment_) {
                    flag = true;
                    break
                }
            }

            if (punishment_ < 1) {
                punishment_ = 6
            }
            if (flag) break
        }

        if (punishment_ !== punishment.punishment) {
            runInAction(() => {
                punishment.punishment = punishment_
            })
            await props.updateSettings(true);
        }
    }

    async function onRoleSelect(event: SyntheticEvent<HTMLSelectElement>) {
        if (event.currentTarget.value !== punishment.role_id) {
            runInAction(() => {
                punishment.role_id = event.currentTarget.value
            })
            await props.updateSettings(true);
        }
    }
    let availableRoles: IGuildRole[] = []
    if (punishment.punishment === PunishmentEnum.give_role) {
        for (const role of Object.values(props.availablePunishmentsAndRoles.roles)) {
            if (role.is_admin) continue
            availableRoles.push(role)
        }
    } else {
        availableRoles = Object.values(props.availablePunishmentsAndRoles.roles)
    }


    return (
        <div className='punishment-block'>
            <div className='punishment-header'>
                <span className='punishment-title'>
                    Наказание
                </span>
                <PunishmentSelector onRight={onRightPunishmentSelector}
                                    onLeft={onLeftPunishmentSelector}
                                    text={LOCALIZATIONS.punishments[punishment.punishment]}/>

            </div>
            {
                punishment.punishment !== PunishmentEnum.kick &&
                    <div className='punishment-setting-container'>
                        <span className='punishment-title'>
                            Длительность
                        </span>
                        <div className='punishment-duration-grid'>
                            <PunishmentDuration type='d' name='дней' time={Math.floor(punishment.duration / 86400)}
                                                punishment={punishment} updateSettings={props.updateSettings}/>
                            <PunishmentDuration type='h' name='часов' time={Math.floor(punishment.duration % 86400 / 3600)}
                                                punishment={punishment} updateSettings={props.updateSettings}/>
                            <PunishmentDuration type='m' name='минут' time={Math.floor(punishment.duration % 86400 % 3600 / 60)}
                                                punishment={punishment} updateSettings={props.updateSettings}/>
                            <PunishmentDuration type='s' name='секунд' time={Math.floor(punishment.duration  % 86400 % 3600 % 60)}
                                                punishment={punishment} updateSettings={props.updateSettings}/>
                        </div>
                    </div>
            }
            {
                (punishment.punishment === PunishmentEnum.take_role || punishment.punishment === PunishmentEnum.give_role) &&
			        <div className='punishment-setting-container'>
                        <span className='punishment-title'>
                            Роль
                        </span>
                        <select onChange={onRoleSelect} defaultValue={punishment.role_id || undefined}>
                            {availableRoles.map((elem) => {
                                return <option value={elem.id}>{elem.name}</option>
                            })}
                        </select>
                    </div>
            }
            <div className='punishment-delete'>
                <button className='punishment-delete-button' onMouseUp={onPunishmentDelete}>
                    <CrossMarkLogo width='20px' height='20px'/>
                </button>
            </div>
        </div>
    )
}

export default observer(Punishment);