import {IGuildChannel} from "../models/IGuildChannel";

export function getChannelName(channel: IGuildChannel): string {
    if (channel.id === 'dm' || channel.id === 'public') {
        return channel.name;
    }

    let name: string = ''
    if (channel.category_name !== null) {
        name =`${channel.category_name} | ${channel.name}`
    } else {
        name = channel.name
    }
    if (channel.type === 0) {
        return `💬|${name}`
    } else if (channel.type === 2) {
        return `🔊|${name}`
    } else {
        return `📂|${name}`
    }
}