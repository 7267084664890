import { createContext } from 'react';

import { Routes, Route } from "react-router-dom";
import Store from "../store/store";



interface IStore {
    store: Store,
  }
  
const store = new Store();

export const AuthContext = createContext<IStore>({store})

export const AuthProvider = ({children}: any) => {
    return <AuthContext.Provider value={{store}}>
        {children}
    </AuthContext.Provider>
}